import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ScrollAnimation from "react-animate-on-scroll";

const MySwal = withReactContent(Swal);

const alertContent = () => {
  MySwal.fire({
    title: "Congratulations!",
    text: "Your message was successfully sent and will be back to you soon.",
    icon: "success",
    timer: 2000,
    timerProgressBar: true,
    showConfirmButton: false,
  });
};

const initState = {
  fullname: "",
  // email: "",
  mobile: "",
  // gender: "",
  // dob: "",
  // city: "",
  // pincode: "",
  // homeAddress: "",
  // test: "",
  // medicalPrescription: null,
};

// const healthPackages = [
//   { key: "MINI_HEALTH_CHECK_UP", name: "MINI HEALTH CHECK UP" },
//   {
//     key: "SILVER_EXECUTIVE_HEALTH_CHECK_UP",
//     name: "SILVER EXECUTIVE HEALTH CHECK UP",
//   },
//   {
//     key: "GOLD_EXECUTIVE_HEALTH_CHECK_UP",
//     name: "GOLD EXECUTIVE HEALTH CHECK UP",
//   },
//   {
//     key: "PLATINUM_EXECUTIVE_HEALTH_CHECK_UP",
//     name: "PLATINUM EXECUTIVE HEALTH CHECK UP",
//   },
//   {
//     key: "COMPREHENSIVE_HEALTH_CHECK_UP",
//     name: "COMPREHENSIVE HEALTH CHECK UP",
//   },
//   { key: "EXTENDED_LIPID_PROFILE", name: "EXTENDED LIPID PROFILE" },
//   {
//     key: "SENIOR_CITIZEN_MALE_HEALTH_CHECK_UP",
//     name: "SENIOR CITIZEN MALE HEALTH CHECK UP",
//   },
//   {
//     key: "SENIOR_CITIZEN_FEMALE_HEALTH_CHECK_UP",
//     name: "SENIOR CITIZEN FEMALE HEALTH CHECK UP",
//   },
//   {
//     key: "WOMEN_WELLNESS_HEALTH_CHECK_UP",
//     name: "WOMEN'S WELLNESS HEALTH CHECK UP",
//   },
//   {
//     key: "WOMEN_FERTILITY_HEALTH_CHECK_UP",
//     name: "WOMEN’S FERTILITY HEALTH CHECK UP",
//   },
//   { key: "ANTENATAL_SCREENING_PACKAGE", name: "ANTENATAL SCREENING PACKAGE" },
//   {
//     key: "DIABETES_CARE_HEALTH_CHECK_UP",
//     name: "DIABETES CARE HEALTH CHECK UP",
//   },
//   {
//     key: "ADVANCED_DIABETES_CARE_HEALTH_CHECK_UP",
//     name: "ADVANCED DIABETES CARE HEALTH CHECK UP",
//   },
//   { key: "FEVER_PANEL_BASIC", name: "FEVER PANEL BASIC" },
//   { key: "FEVER_PANEL_ADVANCED", name: "FEVER PANEL ADVANCED" },
//   { key: "CARDIAC_CARE_CHECK_UP_BASIC", name: "CARDIAC CARE CHECK UP – BASIC" },
//   {
//     key: "CARDIAC_CARE_CHECK_UP_ADVANCED",
//     name: "CARDIAC CARE CHECK UP – ADVANCED",
//   },
//   { key: "PCOS_PCOD_PROFILE", name: "PCOS/PCOD PROFILE" },
//   { key: "ARTHRITIS_PACKAGE_BASIC", name: "ARTHRITIS PACKAGE (BASIC)" },
//   { key: "ARTHRITIS_PACKAGE_ADVANCED", name: "ARTHRITIS PACKAGE (ADVANCED)" },
//   { key: "ANAEMIA_PROFILE", name: "ANAEMIA PROFILE" },
//   { key: "THALASSEMIA_PROFILE", name: "THALASSEMIA PROFILE" },
//   { key: "CANCER_MARKER_PROFILE_MALE", name: "CANCER MARKER PROFILE MALE" },
//   { key: "CANCER_MARKER_PROFILE_FEMALE", name: "CANCER MARKER PROFILE FEMALE" },
//   { key: "VIRAL_MARKER", name: "VIRAL MARKER" },
//   {
//     key: "PRE_OPERATIVE_PRE_ANESTHETIC_CHECKUP",
//     name: "PRE-OPERATIVE / PRE-ANESTHETIC CHECKUP",
//   },
// ];

export default function HomeCollection() {
  const [state, setState] = useState(initState);
  const { register, handleSubmit, errors } = useForm();

  const {
    fullname,
    // email,
    mobile,
    // gender,
    // dob,
    // city,
    // pincode,
    // homeAddress,
    // test,
    // medicalPrescription,
  } = state;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  // const handleFileChange = (e) => {
  //   const { name, files } = e.target;
  //   setState((prevState) => ({ ...prevState, [name]: files[0] }));
  // };

  const onSubmit = async () => {
    try {
      const payload = {
        fullname,
        // email,
        mobile,
        // gender,
        // dob,
        // city,
        // pincode,
        // homeAddress,
        // test,
        // medicalPrescription,
      };

      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      console.log(formData);

      const response = await fetch(
        "https://server-host.dodahealthcare.com/api/home-collection",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (data.status === 401 || !data) {
        console.log("error");
      } else {
        setState(initState);
        alertContent();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="drop-area home-collection-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div className="drop-item drop-img">
              <div className="drop-left">
                <ScrollAnimation animateIn="fadeInUp" offset="100">
                  <h2>Drop your message for home collection</h2>
                </ScrollAnimation>

                <form id="homeCollectionForm" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="fullname"
                          placeholder="Enter your full name"
                          className="form-control"
                          value={state.fullname}
                          onChange={handleChange}
                          ref={register({ required: true })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.fullname && "Please enter name."}
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          placeholder="Enter your email"
                          className="form-control"
                          value={state.email}
                          onChange={handleChange}
                          ref={register({
                            required: true,
                            pattern: /^\S+@\S+$/i,
                          })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.email && "Please enter email."}
                        </div>
                      </div>
                    </div> */}

                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="mobile"
                          placeholder="Enter your mobile number"
                          className="form-control"
                          value={state.mobile}
                          onChange={handleChange}
                          ref={register({ required: true })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.mobile && "Please enter mobile no."}
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <select
                          name="gender"
                          className="form-control"
                          value={state.gender}
                          onChange={handleChange}
                          ref={register({ required: true })}
                        >
                          <option value="">Select Your gender</option>
                          <option value="female">Female</option>
                          <option value="male">Male</option>
                          <option value="other">Other</option>
                        </select>
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.gender && "Please select gender."}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <input
                          type="date"
                          name="dob"
                          className="form-control"
                          value={state.dob}
                          max={new Date().toISOString().split("T")[0]} // Set the max date to today's date
                          onChange={handleChange}
                          ref={register({ required: true })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.dob && "Please select DOB."}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="city"
                          placeholder="Enter your city"
                          className="form-control"
                          value={state.city}
                          onChange={handleChange}
                          ref={register({ required: true })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.city && "Please enter city."}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="pincode"
                          placeholder="Enter your pincode"
                          className="form-control"
                          value={state.pincode}
                          onChange={handleChange}
                          ref={register({ required: true })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.pincode && "Please enter pincode."}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-8">
                      <div className="form-group">
                        <textarea
                          name="homeAddress"
                          cols="30"
                          rows="1"
                          placeholder="Enter your home address"
                          className="form-control"
                          value={state.homeAddress}
                          onChange={handleChange}
                          ref={register({ required: true })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.homeAddress && "Please enter address."}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-8">
                      <div className="form-group">
                        <select
                          name="test"
                          className="form-control"
                          value={state.test}
                          onChange={handleChange}
                          ref={register({ required: true })}
                        >
                          <option value="">Select your test</option>
                          {healthPackages.map((el) => (
                            <option value={el.key} key={el.key}>
                              {el.name}
                            </option>
                          ))}
                        </select>
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.test && "Please select health package."}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        <i className="icofont-upload-alt"></i>
                        <input
                          type="file"
                          name="medicalPrescription"
                          className="form-control file-upload"
                          onChange={handleFileChange}
                          accept=".pdf,.jpg,.jpeg,.png"
                          ref={register()} // Removed required validation
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.medicalPrescription &&
                            "You might have forgotten to upload the prescription."}
                        </div>
                      </div>
                    </div> */}

                    <div className="col-lg-4 col-md-4">
                      <button type="submit" className="drop-btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
