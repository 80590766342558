import { useCallback, useEffect, useState } from "react";

const useAxios = () => {
  const [state, setState] = useState({
    loading: false,
    response: [],
    error: null,
    controller: null,
  });

  const { loading, response, error, controller } = state;

  const axiosFetch = useCallback(async (configObj) => {
    const { axiosInstance, method, url, requestConfig = {} } = configObj;
    try {
      setState((prev) => {
        return {
          ...prev,
          loading: true,
          response: [],
          error: null,
          controller: null,
        };
      });

      const abortController = new AbortController();

      const axiosResponse = await axiosInstance[method.toLowerCase()](url, {
        ...requestConfig,
        signal: abortController.signal,
      });

      setState((prev) => {
        return {
          ...prev,
          controller: abortController,
          response: axiosResponse.data,
          loading: false,
        };
      });
    } catch (err) {
      setState((prev) => {
        return {
          ...prev,
          error: err,
          loading: false,
        };
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      controller && controller.abort();
    };
  }, [controller]);

  return [loading, response, error, axiosFetch];
};

export default useAxios;
