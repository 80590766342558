import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const QualityAssurancePage = () => {
  return (
    <React.Fragment>
      <div className="nk__qualityAssurance">
        <div className="bg-purple ptb-70">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title">
                  <ScrollAnimation animateIn="fadeInUp" offset="100">
                    <h2>Quality Assurance</h2>
                  </ScrollAnimation>
                </div>

                <div className="col-12">
                  <a
                    href="#"
                    target="_blank"
                    className="nk__float_left"
                    rel="noreferrer"
                  >
                    <img
                      src="/images/nabh-logo.png"
                      alt="nabh-logo"
                      className="img-fluid"
                    />
                    <span>MIS-2016-0034</span>
                  </a>
                  <h3>NABH-MIS</h3>
                  <p>
                    Dr Doda’s centre has accreditation from National
                    Accreditation Board for Hospitals & Healthcare Providers for
                    Medical Imaging services.(NABH-MIS), which is a constituent
                    board of Quality Council of India.
                  </p>

                  <p>
                    Medical Imaging Services cover investigations of patients
                    that provide imaging information for diagnosis, prevention,
                    and treatment of disease; or assessment of health. It
                    includes conventional radiation based diagnostic radiology
                    as well as a wide variety of specialised techniques
                    including Ultrasound scans, Doppler studies, Bone
                    densitometry, CT, MRI.
                  </p>

                  <p>
                    All standard of services(statutory or otherwise) at our
                    centre meet the needs of all patient and the clinical
                    personnel responsible for the care of patients. The services
                    include arrangement for requisition, choice of appropriate
                    (most informative and cost effective) imaging techniques,
                    patient information, patient consent, patient preparation,
                    patient identification, performance of imaging procedures,
                    interpretation, reporting and advice regarding the result,
                    in addition to the consideration of safety and ethics in
                    diagnostic imaging services.
                  </p>

                  <p>
                    NABH certification is a Mark of excellence.As NABH
                    Accredited centre we adhere to the set benchmarks and cater
                    to the much desired needs of the patients with high quality
                    of care and patient safety. The patients are provided
                    services by credential medical staff. Rights of patients are
                    respected and protected and patient satisfaction is
                    regularly evaluated.
                  </p>

                  <p>
                    Accreditation of the centre stimulates continuous
                    improvement of the facilities. It has enabled us in
                    demonstrating commitment to quality care and raised
                    community confidence in the services provided by us. It has
                    provided us the opportunity to benchmark with the best.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-dark-grey ptb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <a
                  href="/images/nabl-Certificate.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* <span>
                    <img
                      src="/images/ilac-mra-logo.png"
                      alt="ilac-mra-logo"
                      className="img-fluid"
                    />
                  </span> */}
                  <span>
                    <img
                      src="/images/nabl-logo.png"
                      alt="nabh-logo"
                      className="img-fluid m-0"
                    />
                    <span>
                      ISO 15189:2022
                      <br />
                      MC-2625
                    </span>
                  </span>
                </a>

                <h3>NABL</h3>
                <p>
                  Dr Doda’s centre has accreditation from National Accreditation
                  Board for Hospitals & Healthcare Providers for Medical Imaging
                  services.(NABH-MIS), which is a constituent board of Quality
                  Council of India.
                </p>

                <p>
                  Medical Imaging Services cover investigations of patients that
                  provide imaging information for diagnosis, prevention, and
                  treatment of disease; or assessment of health. It includes
                  conventional radiation based diagnostic radiology as well as a
                  wide variety of specialised techniques including Ultrasound
                  scans, Doppler studies, Bone densitometry, CT, MRI.
                </p>

                <p>
                  All standard of services(statutory or otherwise) at our centre
                  meet the needs of all patient and the clinical personnel
                  responsible for the care of patients. The services include
                  arrangement for requisition, choice of appropriate (most
                  informative and cost effective) imaging techniques, patient
                  information, patient consent, patient preparation, patient
                  identification, performance of imaging procedures,
                  interpretation, reporting and advice regarding the result, in
                  addition to the consideration of safety and ethics in
                  diagnostic imaging services.
                </p>

                <p>
                  NABH certification is a Mark of excellence.As NABH Accredited
                  centre we adhere to the set benchmarks and cater to the much
                  desired needs of the patients with high quality of care and
                  patient safety. The patients are provided services by
                  credential medical staff. Rights of patients are respected and
                  protected and patient satisfaction is regularly evaluated.
                </p>

                <p>
                  Accreditation of the centre stimulates continuous improvement
                  of the facilities. It has enabled us in demonstrating
                  commitment to quality care and raised community confidence in
                  the services provided by us. It has provided us the
                  opportunity to benchmark with the best.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="ptb-70">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3>Proficiency Testing</h3>
                <p>
                  Internal quality control (IQC) and External quality assessment
                  (EQA), are referred to as proficiency testing. These are two
                  distinct, yet complementary, components of a laboratory
                  quality assurance program.
                </p>
              </div>
              <div className="col-12">
                <div className="nk__tab_content">
                  <p className="nk__content_title">
                    Internal quality control (IQC)
                  </p>
                  <p>
                    Internal quality control (IQC) is useful to detect, reduce
                    and correct deficiencies in the laboratory`s analytical
                    process prior to the release of patient results. Stringent
                    practice of quality control helps our laboratory to achieve
                    better performance goals. In addition to the daily routine,
                    IQC material is run after an instrument is serviced, after
                    reagents are changed, after calibration and when patient
                    results seem inappropriate. Quality control data is
                    interpreted by both graphical and statistical methods using
                    the Levey Jennings(LJ) chart. The control values are plotted
                    against their respective dates. The mean value and one, two
                    and three standard deviation limits are also marked. The
                    pattern of plotted points provides information of random
                    error, shifts or trends in the control runs.
                  </p>
                  <p>
                    The internal quality control system helps in immediate
                    intervention during the release of patients results. The
                    laboratory personnel performing this exercise determines the
                    appropriate corrective action to be taken for QC data that
                    fall outside the tolerance limits.
                  </p>
                  <p>
                    The daily control values are documented along with
                    calculation of % CV from the monthly quality control data
                    and the control charts are maintained.
                  </p>
                </div>
                <div className="nk__tab_content">
                  <p className="nk__content_title">
                    External quality assessment (EQA)
                  </p>
                  <p>
                    External Quality Assessment is used to identify the degree
                    of agreement between our laboratory`s results and those
                    obtained by others. EQAS provides information concerning the
                    relative performance of analytical procedures, including the
                    method principle, reagents, and instruments. Continued
                    participation in EQA schemes has been linked to improvement
                    in our laboratory performance.
                  </p>
                  <p>
                    We are committed to perform EQA testing on regular basis to
                    ensure the best quality of our services:
                  </p>

                  <p className="mt-25">
                    <strong>Clinical Biochemistry & Immunology</strong>
                  </p>
                  <ul>
                    <li>Biorad (Clinical Biochemistry)</li>
                    <li>Biorad (Immunology)</li>
                  </ul>
                  <p className="mt-25">
                    <strong>Microbiology and Serology</strong>
                  </p>
                  <ul>
                    <li>Indian Association Of Medical Microbiologists(IAMM)</li>
                    <li>BEQAS - Jaipur (Serology)</li>
                  </ul>
                  <p className="mt-25">
                    <strong>Haematology</strong>
                  </p>
                  <ul>
                    <li>AIIMS - (EQAS)</li>
                    <li>CMC Vellore - EQAS</li>
                    <li>Biorad - (Hemoglobin Programme)</li>
                    <li>CMC Vellore - (Coagulation programme)</li>
                    <li>BEQAS - Jaipur</li>
                  </ul>
                  <p className="mt-25">
                    <strong>Cytopathology</strong>
                  </p>
                  <ul>
                    <li>Indian Academy of Cytologists (IAC) EQAP</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QualityAssurancePage;
