import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

// let images = [
//   {
//     url: "https://picsum.photos/id/1018/1000/600/",
//     title: "image title 1",
//     startIndex: 0,
//   },
//   {
//     url: "https://picsum.photos/id/1015/1000/600/",
//     title: "image title 2",
//   },
// ];

const GalleryPage = () => {
  return (
    <React.Fragment>
      <div className="gallery-page ptb-70">
        <div className="container">
          {/* <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Our Healthcare Gallery</h2>
            </ScrollAnimation>
          </div> */}
          <div className="row">
            <div className="col-md-12">
              <SimpleReactLightbox>
                <SRLWrapper>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_19.jpg">
                          <img src="/images/gallery/thumb_19.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_32.jpg">
                          <img src="/images/gallery/thumb_32.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_36.jpg">
                          <img src="/images/gallery/thumb_36.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_39.jpg">
                          <img src="/images/gallery/thumb_39.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_40.jpg">
                          <img src="/images/gallery/thumb_40.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_41.jpg">
                          <img src="/images/gallery/thumb_41.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_43.jpg">
                          <img src="/images/gallery/thumb_43.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_44.jpg">
                          <img src="/images/gallery/thumb_44.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_45.jpg">
                          <img src="/images/gallery/thumb_45.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_46.jpg">
                          <img src="/images/gallery/thumb_46.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_47.jpg">
                          <img src="/images/gallery/thumb_47.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_48.jpg">
                          <img src="/images/gallery/thumb_48.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_49.jpg">
                          <img src="/images/gallery/thumb_49.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_50.jpg">
                          <img src="/images/gallery/thumb_50.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_51.jpg">
                          <img src="/images/gallery/thumb_51.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_52.jpg">
                          <img src="/images/gallery/thumb_52.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_53.jpg">
                          <img src="/images/gallery/thumb_53.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_54.jpg">
                          <img src="/images/gallery/thumb_54.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_56.jpg">
                          <img src="/images/gallery/thumb_56.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_57.jpg">
                          <img src="/images/gallery/thumb_57.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_58.jpg">
                          <img src="/images/gallery/thumb_58.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_59.jpg">
                          <img src="/images/gallery/thumb_59.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_60.jpg">
                          <img src="/images/gallery/thumb_60.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_61.jpg">
                          <img src="/images/gallery/thumb_61.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_62.jpg">
                          <img src="/images/gallery/thumb_62.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_63.jpg">
                          <img src="/images/gallery/thumb_63.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_64.jpg">
                          <img src="/images/gallery/thumb_64.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_65.jpg">
                          <img src="/images/gallery/thumb_65.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_66.jpg">
                          <img src="/images/gallery/thumb_66.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_67.jpg">
                          <img src="/images/gallery/thumb_67.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_69.jpg">
                          <img src="/images/gallery/thumb_69.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_70.jpg">
                          <img src="/images/gallery/thumb_70.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 nk__gallery_wrap">
                      <div>
                        <a href="/images/gallery/thumb_71.jpg">
                          <img src="/images/gallery/thumb_71.jpg" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GalleryPage;
