import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const PulmonologyPage = () => {
  return (
    <React.Fragment>
      <div className="nk__qualityAssurance ptb-70 nk__radiology_page nk__faclities_sub-page">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Pulmonology</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="nk__tab_content">
                <p className="nk__content_title">
                  Pulmonary Function Test (PFT)
                </p>
                <p>
                  PFT is also known as spirometry or lung function test.
                  Pulmonary Function Test measure how well the lungs work to
                  bring oxygen to the rest of your body. PFT is done in patients
                  having symptoms of pulmonary disease to monitor effectiveness
                  of treatment of a lung disease, such as asthma and to assess
                  working of lungs prior to surgery.
                </p>
                <p className="nk__content_title">Main Indications:</p>
                <ul>
                  <li>Asthma</li>
                  <li>Allergies</li>
                  <li>Chronic bronchitis</li>
                  <li>Respiratory infections</li>
                  <li>Respiratory infections</li>
                  <li>Bronchiectasis</li>
                  <li>Chronic obstructive pulmonary disease (COPD)</li>
                  <li>Asbestosis</li>
                  <li>Sarcoidosis</li>
                  <li>Scleroderma</li>
                  <li>Pulmonary Tumor</li>
                  <li>Lung cancer</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PulmonologyPage;
