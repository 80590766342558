import React from "react";
import HeroSlider from "../components/HeroSlider";
import AboutSection from "../components/AboutSection";
import FacilitiesBox from "../components/FacilitiesBox";
import TestimonailSlider from "../components/TestimonailSlider";
// import HealthPackages from "../components/HealthPackages";
import OurDoctors from "../components/OurDoctors";
import Stats from "../components/Stats";
import HealthPackagesSlider from "../components/HealthPackagesSlider/HealthPackagesSlider";

const HomePage = () => {
  return (
    <React.Fragment>
      <HeroSlider />
      <AboutSection />
      <FacilitiesBox />
      <TestimonailSlider />
      {/* <HealthPackages /> */}
      <HealthPackagesSlider />
      <OurDoctors />
      <Stats />
    </React.Fragment>
  );
};

export default HomePage;
