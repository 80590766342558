import React from "react";
import OwlCarousel from "react-owl-carousel3";
import ScrollAnimation from "react-animate-on-scroll";

const options = {
  items: 4,
  loop: true,
  margin: 15,
  nav: true,
  dots: true,
  smartSpeed: 1000,
  autoplay: true,
  autoplayHoverPause: true,
  stagePadding: 0,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsiveClass: false,
  responsive: {
    0: {
      items: 1,
      nav: true,
    },
    480: {
      items: 2,
      nav: true,
    },
    768: {
      items: 3,
      nav: true,
    },
    991: {
      items: 3,
      nav: true,
    },
    1000: {
      items: 4,
      nav: true,
      loop: false,
    },
  },
};

const PartnerSider = () => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);
  return (
    <React.Fragment>
      <div className="nk_facilities_slider_container ptb-70">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Technology Partners</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="nk__facilities_slider">
                {display ? (
                  <OwlCarousel
                    className="facility-slider owl-carousel owl-theme"
                    {...options}
                  >
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/liver-fibroScan.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-1.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-2.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-3.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-4.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-5.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-6.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-7.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-8.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-9.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-10.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-11.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-12.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-13.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-14.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-15.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-16.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-17.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-18.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_front">
                          <img
                            src="/images/partners/tech-partner-19.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PartnerSider;
