import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { resetIdCounter, Tab, Tabs, TabList, TabPanel } from "react-tabs";

resetIdCounter();

const LaboratoriesPage = () => {
  return (
    <React.Fragment>
      <div className="nk__qualityAssurance ptb-70 nk__radiology_page nk__faclities_sub-page">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Laboratories</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-12">
              <Tabs>
                <TabList>
                  <Tab>Haematology</Tab>
                  <Tab>Bio-Chemistry and Immunology</Tab>
                  <Tab>Microbiology</Tab>
                  <Tab>Clinical Pathology</Tab>
                  <Tab>Cytopathology / Histopathology</Tab>
                </TabList>
                <TabPanel>
                  <div className="nk__tab_content">
                    <h4>Haematology</h4>
                    <div className="table-responsive mb-5">
                      <table
                        class="table table-bordered table-hover"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <th width="28%">
                              A .E.C./ Absolute Eosinophil Count
                            </th>
                            <th width="20%">Blood (EDTA)</th>
                            <th width="10%">Same Day</th>
                            <th>Flow cytometry/ Sysmex 1000</th>
                          </tr>
                          <tr>
                            <td>
                              Activated Partial Thromboplastin Time (APTT)
                            </td>
                            <td>Citrated Blood</td>
                            <td>Same Day</td>
                            <td>Sysmex CA 50</td>
                          </tr>
                          <tr>
                            <td>Blood Group</td>
                            <td>EDTA Blood</td>
                            <td>Same Day</td>
                            <td>
                              ID card - LISS Method - Column
                              agglutination/BIOVUE
                            </td>
                          </tr>
                          <tr>
                            <td>Blood For Sickling</td>
                            <td>EDTA Blood</td>
                            <td>Same Day</td>
                            <td>HPLC +Sodium metabisulphite</td>
                          </tr>
                          <tr>
                            <td>Bone Marrow</td>
                            <td>Bone Marrow</td>
                            <td>2 Days</td>
                            <td>Microscopic Examination</td>
                          </tr>
                          <tr>
                            <td>Coomb’s Test - Direct / Indirect</td>
                            <td>EDTA Blood / serum</td>
                            <td>Same Day</td>
                            <td>
                              ID card - LISS Method - Column
                              agglutination/BIOVUE
                            </td>
                          </tr>
                          <tr>
                            <td>
                              CBC(Hb, MCV, MCH, MCHC,TLC,PCV,Platelet count, RBC
                              Count
                            </td>
                            <td>EDTA Blood</td>
                            <td>Same Day</td>
                            <td>
                              Optical, Fluorescence, flow cytometry and SLS -
                              Hb/ Sysmex 1000
                            </td>
                          </tr>
                          <tr>
                            <td>ESR</td>
                            <td>EDTA Blood</td>
                            <td>Same Day</td>
                            <td>(Automated) Westergren/ Vesmatic 20/ Easy</td>
                          </tr>
                          <tr>
                            <td>G6PD</td>
                            <td>EDTA Blood</td>
                            <td>Same Day</td>
                            <td>Methylene Blue Reduction</td>
                          </tr>
                          <tr>
                            <td>HbA2</td>
                            <td>EDTA Blood</td>
                            <td>3 days</td>
                            <td>
                              HPLC using Beta thal short program/ BioRad - D10
                            </td>
                          </tr>
                          <tr>
                            <td>LE Cell</td>
                            <td>Defibrinated Blood</td>
                            <td>Same Day</td>
                            <td>Antigen Antibody reaction &amp; Microscopy</td>
                          </tr>
                          <tr>
                            <td>Lupus Anticoagulant(LA1,LA2)</td>
                            <td>Citrated Blood</td>
                            <td>Same Day</td>
                            <td>
                              ScreenLA1 / Confirmation LA2 (Diluted Russel Venom
                              Test,DRVTT) / CA 50
                            </td>
                          </tr>
                          <tr>
                            <td>Osmotic Fragility</td>
                            <td>Heparinised Blood</td>
                            <td>Same Day</td>
                            <td>Tube Method</td>
                          </tr>
                          <tr>
                            <td>Peripheral Smear</td>
                            <td>Blood</td>
                            <td>Same Day</td>
                            <td>Microscopic</td>
                          </tr>
                          <tr>
                            <td>Prothrombin Time (PT)</td>
                            <td>Citrated Blood</td>
                            <td>Same Day</td>
                            <td>Sysmex CA50</td>
                          </tr>
                          <tr>
                            <td>Rapid Malaria Test</td>
                            <td>EDTA Blood</td>
                            <td>Same Day</td>
                            <td>
                              Detection of Plasmodium lactate dehydrogenase
                              using monoclonal antibodies (Dipstick)
                            </td>
                          </tr>
                          <tr>
                            <td>Reticulocyte Count</td>
                            <td>EDTA Blood</td>
                            <td>Same Day</td>
                            <td>Supravital Staining (New Methylene Blue)</td>
                          </tr>
                          <tr>
                            <td>Coagulation Profile</td>
                            <td>EDTA, Citrate Blood,</td>
                            <td>2 Days</td>
                            <td>Sysmex CA50</td>
                          </tr>
                          <tr>
                            <td>Thrombophilia Profile</td>
                            <td>EDTA, Citrate &amp; Clotted Blood,</td>
                            <td>7 Days</td>
                            <td>Fully automated</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="nk__tab_content">
                    <h4>Immunology, Serology And Hormones</h4>
                    <div className="table-responsive mb-5">
                      <table
                        class="table table-bordered table-hover"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <th width="25%">Test - Biochemistry</th>
                            <th width="25%">Specimen Required</th>
                            <th width="10%">Reporting</th>
                            <th>Methodology / Equipment used</th>
                          </tr>
                          <tr>
                            <td>Albumin</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Colorimetric Assay with end point (Bromocresol
                              Green method) / VITROS 5.1 FS
                            </td>
                          </tr>
                          <tr>
                            <td>Adenosine Deaminase</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>ELISA/ Thermo</td>
                          </tr>
                          <tr>
                            <td>Adenosine Deaminase</td>
                            <td>Fluid</td>
                            <td>Same Day</td>
                            <td>ELISA/ Thermo</td>
                          </tr>
                          <tr>
                            <td>Adenosine Deaminase</td>
                            <td>CSF</td>
                            <td>Same Day</td>
                            <td>ELISA/Thermo</td>
                          </tr>
                          <tr>
                            <td>Alkaline Phosphatase</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Kinetic (Para Nitrophenyl Phosphate) Method VITROS
                              5.1 FS
                            </td>
                          </tr>
                          <tr>
                            <td>Amylase</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Enzymatic Colorimetric Assay ( Kientic ) VITROS
                              5.1 FS
                            </td>
                          </tr>
                          <tr>
                            <td>APO A1</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Immunoturbidimetric Assay / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>APO B</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Immunoturbidimetric Assay / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Alfa - Fetoprotein</td>
                            <td>Blood sample (clotted) , Aminotic Fluid</td>
                            <td>Same Day</td>
                            <td>
                              Chemiluminescence Immunoassay ( CLIA ) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>Anti - CCP</td>
                            <td>Blood sample (clotted)</td>
                            <td>Next Day</td>
                            <td>CLIA / Immulite 2000</td>
                          </tr>
                          <tr>
                            <td>Anti - Cardiolipin Antibodies IgG</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>ELISA / Thermo</td>
                          </tr>
                          <tr>
                            <td>Anti - Cardiolipin Antibodies IgM</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>ELISA/ Thermo</td>
                          </tr>
                          <tr>
                            <td>Adrenocorticotropic Hormone ( ACTH )</td>
                            <td>Plasma ( EDTA )</td>
                            <td>Next Day</td>
                            <td>
                              Chemiluminescence Immunoassay ( CLIA ) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>Androstenedione</td>
                            <td>Blood sample (clotted)</td>
                            <td>7 Days</td>
                            <td>Enzyme-Linked Immunosorbent Assay ( ELISA )</td>
                          </tr>
                          <tr>
                            <td>
                              Anti - Tissue Transglutaminase Antibodies IgA
                            </td>
                            <td>Blood sample (clotted)</td>
                            <td>2 Days</td>
                            <td>
                              Enzyme-Linked Immunosorbent Assay ( ELISA ) /
                              Microwell plate ELISA reader
                            </td>
                          </tr>
                          <tr>
                            <td>Anti - Thyroid peroxidase (Anti-TPO)</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>CLIA / Immulite 2000</td>
                          </tr>
                          <tr>
                            <td>Anti Thyroglobulin (Anti-TG)</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>CLIA / Immulite 2000</td>
                          </tr>
                          <tr>
                            <td>Bilirubin ( Direct )</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Colorimetiric Assay ( Diazo Method ) / VITROS 5.1
                              FS
                            </td>
                          </tr>
                          <tr>
                            <td>Billirubin ( Total )</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Colorimetiric Assay ( Diazo Method ) / VITROS 5.1
                              FS
                            </td>
                          </tr>
                          <tr>
                            <td>Beta - HCG</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Chemiluminescence Immunoassay ( CLIA ) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>B-12 ( Vitamin )</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay ( CLIA ) /
                              Vitros ECi
                            </td>
                          </tr>
                          <tr>
                            <td>Calcium</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Colorimetiric Assay end point / VITROS 5.1 FS
                            </td>
                          </tr>
                          <tr>
                            <td>Calcium</td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>
                              Colorimetiric Assay end point / VITROS 5.1 FS
                            </td>
                          </tr>
                          <tr>
                            <td>Chloride</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Ion Selective Electrode / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Cholestrol Total</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>CHOD-Pap Method VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>CK ( Creatine Kinase</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Enzymatic / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>CK - MB</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Enzymatic / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Creatinine Clearance 24 Hrs</td>
                            <td>Blood sample (clotted) + 24 Hrs Urine</td>
                            <td>Same Day</td>
                            <td>Calculated</td>
                          </tr>
                          <tr>
                            <td>Creatinine (standardized against IDMS)</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Kinetic (Jaffes) Method / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Creatinine</td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>Enzymatic / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>CA 125</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>CA 15.3</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>CA 19.9</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>CEA</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Chemiluminescence Immunoassay (CLIA) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>Cortisol</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Chemiluminescence Immunoassay (CLIA) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>C-Peptide</td>
                            <td>Blood sample (clotted)</td>
                            <td>Next Day</td>
                            <td>
                              Chemiluminescence Immunoassay (CLIA) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>Double marker test(FTST)</td>
                            <td>Blood sample (clotted</td>
                            <td>Next day</td>
                            <td>
                              Chemiluminescence Immunoassay (CLIA) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>DHEA-S</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Chemiluminescence Immunoassay (CLIA) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>Estradiol</td>
                            <td>Blood sample (clotted) /</td>
                            <td>Next Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>Free Estriol</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Competitive Chemiluminescent enzyme immunoassay /
                              Immulite 2000
                            </td>
                          </tr>
                          <tr>
                            <td>Ferritin</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>Free T3</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>Free T4</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>Free Beta HCG</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Chemiluminescent Immunometric Assay (CLIA) /
                              Immulite 2000
                            </td>
                          </tr>
                          <tr>
                            <td>Free Testosterone</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Chemiluminescent Immunometric Assay (CLIA) /
                              Immulite 2000
                            </td>
                          </tr>
                          <tr>
                            <td>FSH</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>Folate</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Chemiluminescence Immunoassay (CLIA) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>Glucose</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>GOD-POD / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Gamma GT</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Kinetic / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Growth hormone</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Chemiluminescence Immunoassay (CLIA) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>Glyco haemoglobin</td>
                            <td>EDTA Blood</td>
                            <td>Same Day</td>
                            <td>
                              Ion exchange high performance liquid
                              chromatography / Bio-Rad D10
                            </td>
                          </tr>
                          <tr>
                            <td>Homocysteine</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Immunoturbidity / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>HDL - Cholesterol</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Enzymatic / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>IgA</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Immunoturbidimetric Assay / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>IgE</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Chemiluminescence Immunoassay (CLIA) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>IgG</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Immunoturbidimetric Assay / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>IgM</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Immunoturbidimetric Assay / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Insulin Level</td>
                            <td>Plasma/Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Chemiluminescence Immunoassay (CLIA) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>Iron</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Colorimetric Method / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>LH</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>LDH</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Lipase</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Enzymatic / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Lipo (a)</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Immunoturbimetric assay / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Cholesterol LDL</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Enzymatic / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Magnesium</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Colorimetric Method / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Microalbumin ( 24 hrs)</td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>Immunoturbidimetric assay / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Microalbumin ( Spot)</td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>Immunoturbidimetric assay / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Osmolality,Blood sample (clotted)</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Calculated</td>
                          </tr>
                          <tr>
                            <td>Phosphorus</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Colorimetric / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Phosphorus</td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>Colorimetric / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Potassium</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Ion Selective Electrode / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Procalcitonin</td>
                            <td>Blood sample (clotted)</td>
                            <td>Next Day</td>
                            <td>CLIA / Immulite 2000</td>
                          </tr>
                          <tr>
                            <td>Protein</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Biuret Reagent Colorimetric Assay method/ VITROS
                              5.1 FS
                            </td>
                          </tr>
                          <tr>
                            <td>Protein</td>
                            <td>CSF</td>
                            <td>Same Day</td>
                            <td>Immunoturbidimetric assay / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Protein / Creatinine ratio</td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>
                              As indicated in individual tests / VITROS 5.1 FS
                            </td>
                          </tr>
                          <tr>
                            <td>Proteins 24 HRS</td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>Immunoturbidimetric assay / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>PSA Free</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Chemiluminescence Immunoassay (CLIA) / VITROS 5.1
                              FS
                            </td>
                          </tr>
                          <tr>
                            <td>PSA Total</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECi
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Pregnancy Associated Plasma Protein A ( PAPP A)
                            </td>
                            <td>Blood sample (clotted)</td>
                            <td>Next day</td>
                            <td>
                              Enyme labeled Chemiluminescent Immunometric Assay
                              / Immulite 2000
                            </td>
                          </tr>
                          <tr>
                            <td>Progesterone</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>Prolactin</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>Parathyroid hormone( PTH)</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>N -terminal pro B-type natriuretic peptide</td>
                            <td>Blood sample (clotted)</td>
                            <td>Next day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA)
                            </td>
                          </tr>
                          <tr>
                            <td>Serum free light chain</td>
                            <td>Blood sample (clotted)</td>
                            <td>After 3 days</td>
                            <td>Automated / CLIA</td>
                          </tr>
                          <tr>
                            <td>SGOT(AST)</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              IFCC without Pyridoxal Phosphate/ VITROS 5.1 FS /
                            </td>
                          </tr>
                          <tr>
                            <td>SGPT(ALT)</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Kinetic with Pyridoxal Phosphate / VITROS 5.1 FS
                            </td>
                          </tr>
                          <tr>
                            <td>Sodium</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Ion Selective Electrode / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Triglycerides</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Enzymatic / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Testosterone (Total)</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>Thyroglobulin</td>
                            <td>Blood sample (clotted)</td>
                            <td>Next day</td>
                            <td>
                              Chemiluminescence Immunoassay (CLIA) / Immulite
                              2000
                            </td>
                          </tr>
                          <tr>
                            <td>TSH</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electro chemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI/Immulite
                            </td>
                          </tr>
                          <tr>
                            <td>Troponin I</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Card Method</td>
                          </tr>
                          <tr>
                            <td>Urea</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Kinetic with urease / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Uric Acid</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Colorimetric / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Uric Acid (24hrs)</td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>Colorimetric / VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>VLDL Cholesterol</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Calculated</td>
                          </tr>
                          <tr>
                            <td>25 OH Vitamin D</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Electrochemiluminescence Immunoassay (ECLIA) /
                              VITROS ECI
                            </td>
                          </tr>
                          <tr>
                            <td>17-OH-Progesterone</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>
                              Enzyme linked immunosorbent assay (ELISA)/Thermo
                            </td>
                          </tr>
                          <tr>
                            <td>PCOD Profile</td>
                            <td>Blood sample (clotted)</td>
                            <td>3 Days</td>
                            <td>Fully automated</td>
                          </tr>
                          <tr>
                            <td>Lipid Profile</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Fully automated VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Liver Profile</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Fully automated VITROS 5.1 FS</td>
                          </tr>
                          <tr>
                            <td>Kidney/Renal Profile</td>
                            <td>Blood sample (clotted)</td>
                            <td>Same Day</td>
                            <td>Fully automated VITROS 5.1 FS</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="table-responsive mb-5">
                      <table
                        class="table table-bordered table-hover"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <th width="25%">Test - Immunology</th>
                            <th>Specimen Required</th>
                            <th>Report</th>
                            <th>Method/Instrument</th>
                          </tr>
                          <tr>
                            <td>Allergy Profile</td>
                            <td>Serum</td>
                            <td>4 Days</td>
                            <td>&nbsp; Immulite 2000</td>
                          </tr>
                          <tr>
                            <td>Anti Mitochondrial Antibody(AMA)</td>
                            <td>Serum</td>
                            <td>2 Days</td>
                            <td>Indirect Immunofluorescence</td>
                          </tr>
                          <tr>
                            <td>Auto Immune Liver Profile (ALIP)</td>
                            <td>Serum</td>
                            <td>7 Days</td>
                            <td>Line Blot Assay</td>
                          </tr>
                          <tr>
                            <td>Anti Nuclear Antibody</td>
                            <td>Serum</td>
                            <td>2 Days</td>
                            <td>
                              Indirect Immunofluorescence using Hep2 cells and
                              primate liver / Microscopy
                            </td>
                          </tr>
                          <tr>
                            <td>Anti Nuclear Antibody(ANA/ENA Profile)</td>
                            <td>Serum</td>
                            <td>3 Days</td>
                            <td>Line Blot Assay</td>
                          </tr>
                          <tr>
                            <td>
                              Anti Neutrophillic Cytoplasmic Antibody (ANCA)
                            </td>
                            <td>Serum</td>
                            <td>7 Days</td>
                            <td>Indirect Immunofluorescence / Microscopy</td>
                          </tr>
                          <tr>
                            <td>Anti Smooth Muscle Antibody (ASMA)</td>
                            <td>Serum</td>
                            <td>2 Days</td>
                            <td>Indirect Immunofluorescence / Microscopy</td>
                          </tr>
                          <tr>
                            <td>Anti Double Stranded DNA (ds DNA)</td>
                            <td>Serum</td>
                            <td>2 days</td>
                            <td>Elisa</td>
                          </tr>
                          <tr>
                            <td>
                              Antibodies EBV- Epstein-Barr Virus nuclear
                              antigens
                            </td>
                            <td>Serum</td>
                            <td>3 Days</td>
                            <td>Indirect Immunofluorescence / Microscopy</td>
                          </tr>
                          <tr>
                            <td>Autoantibodies against Gliadin / Endomysium</td>
                            <td>Serum</td>
                            <td>3 Days</td>
                            <td>Indirect Immunofluorescence / Microscopy</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="nk__tab_content">
                    <h4>Microbiology</h4>
                    <div className="table-responsive mb-5">
                      <table
                        class="table table-bordered table-hover"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <th width="25%">Test</th>
                            <th width="35%">Specimen Required</th>
                            <th width="10%">Report</th>
                            <th>Method/Instrument</th>
                          </tr>
                          <tr>
                            <td>AFB Smear</td>
                            <td>Urine / Fluid / Aspirates / Sputum etc.</td>
                            <td>Same Day</td>
                            <td>Ziehl Neelsen Method / Microscopy</td>
                          </tr>
                          <tr>
                            <td>Amoebic Serology IgG</td>
                            <td>Serum</td>
                            <td>7 Days</td>
                            <td>
                              Enzyme linked immunosorbent assay (ELISA)&nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td>Anti-Mullerian Hormone (AMH)</td>
                            <td>Serum</td>
                            <td>3Days</td>
                            <td>Enzyme linked immunosorbent assay (ELISA)</td>
                          </tr>
                          <tr>
                            <td>Blood Culture</td>
                            <td>Whole Blood</td>
                            <td>2 Days</td>
                            <td>Culture / BactAlert 3D</td>
                          </tr>
                          <tr>
                            <td>Clostridium difficile</td>
                            <td>Stool</td>
                            <td>2 Days</td>
                            <td>Genex pert</td>
                          </tr>
                          <tr>
                            <td>Chikungunya IgM</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>Chromatographic Immunoassay / ICT (Rapid)</td>
                          </tr>
                          <tr>
                            <td>Cryptosporidium antigen</td>
                            <td>Stool</td>
                            <td>Same Day</td>
                            <td>Chromatographic Immunoassay / ICT (Rapid)</td>
                          </tr>
                          <tr>
                            <td>Culture (AFB)</td>
                            <td>
                              Sputum / Tissue / Fluid / Pus / Aspirates etc.
                            </td>
                            <td>6 Weeks</td>
                            <td>Liquid culture /&nbsp;Bact Alert 3D</td>
                          </tr>
                          <tr>
                            <td>Culture (Bacterial)</td>
                            <td>
                              Pus / Fluids / CSF/ Throat-swab / Sputum etc
                            </td>
                            <td>2 Days</td>
                            <td>Aerobic method /Manual/&nbsp;Vitek 2</td>
                          </tr>
                          <tr>
                            <td>Culture (Fungal]</td>
                            <td>
                              Pus / Fluids / CSF/ Throat-swab / Sputum etc
                            </td>
                            <td>3 Weeks</td>
                            <td>
                              Saboraud’s Dextrose agar with and without
                              chloromycetin + cyclohexamide /&nbsp;Vitek 2
                            </td>
                          </tr>
                          <tr>
                            <td>Cysticercosis</td>
                            <td>Serum</td>
                            <td>1 Week</td>
                            <td>
                              Enzyme linked immunosorbent assay (ELISA)/&nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td>CMV IgG</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>
                              Enzyme Linked immunosorbent Assay (EIA) /&nbsp;ECI
                            </td>
                          </tr>
                          <tr>
                            <td>CMV IgM</td>
                            <td>Serum</td>
                            <td>Same day</td>
                            <td>
                              Enzyme Linked immunosorbent Assay (EIA) /&nbsp;ECI
                            </td>
                          </tr>
                          <tr>
                            <td>Cryptosporidium</td>
                            <td>Stool</td>
                            <td>Same Day</td>
                            <td>Modified ZN Stain / Microscopy</td>
                          </tr>
                          <tr>
                            <td>CSF for Cryptococcus</td>
                            <td>CSF</td>
                            <td>Same Day</td>
                            <td>India Ink Method / Microscopy</td>
                          </tr>
                          <tr>
                            <td>
                              Chlamydia trachomatis / Neisseria gonorrhoeae
                            </td>
                            <td>Urine/Swab</td>
                            <td>2 Days</td>
                            <td>Genexpert</td>
                          </tr>
                          <tr>
                            <td>Dengue NS1 Antigen</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>Enzyme linked immunosorbent assay (ELISA)</td>
                          </tr>
                          <tr>
                            <td>Dengue Serology (IgG/IgM)</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>Solid phase enzyme immunoassay/ELISA</td>
                          </tr>
                          <tr>
                            <td>Dihydrotestosterone (DHT)</td>
                            <td>Serum</td>
                            <td>7 Days</td>
                            <td>
                              Enzyme linked immunosorbent assay (ELISA) /&nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td>Fungus KOH Prep</td>
                            <td>Scrapings/Skin/Cornea</td>
                            <td>Next Day</td>
                            <td>KOH Preparation method / Manual</td>
                          </tr>
                          <tr>
                            <td>Gram Stain</td>
                            <td>Pus / Fluid / CSF / Culture / Sputum etc.</td>
                            <td>Same Day</td>
                            <td>Gram’s method / Microscopy</td>
                          </tr>
                          <tr>
                            <td>H – Drop for Cholera</td>
                            <td>Stool</td>
                            <td>Same Day</td>
                            <td>Hanging drop method / Microscopy</td>
                          </tr>
                          <tr>
                            <td>Stool for reducing substances</td>
                            <td>Stool</td>
                            <td>Same Day</td>
                            <td>Benedicts Method / Manual</td>
                          </tr>
                          <tr>
                            <td>H. Pylori – IgG</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>Enzyme Immunoassay sandwich</td>
                          </tr>
                          <tr>
                            <td>HAV – IgM</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>ELISA</td>
                          </tr>
                          <tr>
                            <td>HB Core – IgM</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>ELISA</td>
                          </tr>
                          <tr>
                            <td>HBe Antibodies</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>ELISA</td>
                          </tr>
                          <tr>
                            <td>HBeAntigen</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>ELISA) &nbsp;</td>
                          </tr>
                          <tr>
                            <td>HBsAg</td>
                            <td>Serum / Plasma</td>
                            <td>Same Day</td>
                            <td>ECLIAImmunoassay (ECI), Iimmulite</td>
                          </tr>
                          <tr>
                            <td>HBs Antibodies</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>ECLIAImmunoassay (ECI), Iimmulite</td>
                          </tr>
                          <tr>
                            <td>HCV Antibodies</td>
                            <td>Serum / Plasma</td>
                            <td>Same Day</td>
                            <td>ECLIAImmunoassay (ECI), Iimmulite</td>
                          </tr>
                          <tr>
                            <td>HEV – IgM</td>
                            <td>Serum</td>
                            <td>2 Days</td>
                            <td>ECLIAImmunoassay (ECI), Iimmulite</td>
                          </tr>
                          <tr>
                            <td>HSV2 – IgG</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>Enzyme linked immunosorbent assay (ELISA)</td>
                          </tr>
                          <tr>
                            <td>HSV2 – IgM</td>
                            <td>Serum</td>
                            <td>3 Days</td>
                            <td>Enzyme linked immunosorbent assay (ELISA</td>
                          </tr>
                          <tr>
                            <td>HSV 1/2 IgG</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>Enzyme linked immunosorbent assay (ELISA</td>
                          </tr>
                          <tr>
                            <td>HSV 1/2 IgM</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>Enzyme linked immunosorbent assay (ELISA</td>
                          </tr>
                          <tr>
                            <td>HPV</td>
                            <td>Cervical Swab</td>
                            <td>15 Days</td>
                            <td>Genexpert</td>
                          </tr>
                          <tr>
                            <td>MeaslesIgG</td>
                            <td>Serum / Plasma</td>
                            <td>Next Day</td>
                            <td>Chemiluminescence Immunoassay (CLIA) /</td>
                          </tr>
                          <tr>
                            <td>Mumps IgG</td>
                            <td>Serum / Plasma</td>
                            <td>Next Day</td>
                            <td>Chemiluminescence Immunoassay (CLIA) /</td>
                          </tr>
                          <tr>
                            <td>Rubella IgG</td>
                            <td>Serum / Plasma</td>
                            <td>Same Day</td>
                            <td>ELISA</td>
                          </tr>
                          <tr>
                            <td>Rubella IgM</td>
                            <td>Serum / Plasma</td>
                            <td>Same Day</td>
                            <td>ELISA</td>
                          </tr>
                          <tr>
                            <td>Stool Routine</td>
                            <td>Stool</td>
                            <td>Same Day</td>
                            <td>
                              Formal Ether Concentration Method / Microscopy
                            </td>
                          </tr>
                          <tr>
                            <td>Smear for diphtheria</td>
                            <td>Throat swab</td>
                            <td>Same Day</td>
                            <td>Albert stain for C.diphtheria / Microscopy</td>
                          </tr>
                          <tr>
                            <td>Toxo-IgG</td>
                            <td>Serum / Plasma</td>
                            <td>Same Day</td>
                            <td>ELISA</td>
                          </tr>
                          <tr>
                            <td>Toxo-IgM</td>
                            <td>Serum / Plasma</td>
                            <td>Same Day</td>
                            <td>ELISA</td>
                          </tr>
                          <tr>
                            <td>HIV 1 &amp; 2</td>
                            <td>Serum/Plasma</td>
                            <td>Same Day</td>
                            <td>Chemiluminescence Immunoassay EIA</td>
                          </tr>
                          <tr>
                            <td>HIV Tridot</td>
                            <td>Serum/Plasma</td>
                            <td>Same Day</td>
                            <td>Lateral flow chromatography/ Manual</td>
                          </tr>
                          <tr>
                            <td>MTB/RIF</td>
                            <td>
                              Sputum,Pus,BAL,Gastric Lavage,CSF,Peritoneal
                              Fluid,Plural Fluid,Synovial Fluid,Endometrial
                              aspirate,Aspirate from Pouch of
                              Douglas,Biopsies,FNAC
                            </td>
                            <td>Same Day</td>
                            <td>Genexpert</td>
                          </tr>
                          <tr>
                            <td>Urethral Smear-Gonococci</td>
                            <td>Urethral Discharge</td>
                            <td>Same Day</td>
                            <td>Gram’s stain / Microscopy</td>
                          </tr>
                          <tr>
                            <td>Typhidot IgM</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>Immunochromatographic Test / Manual</td>
                          </tr>
                          <tr>
                            <td>
                              Vaginal pathogens for Trichomonas, Gardnerella
                              &amp; Candida
                            </td>
                            <td>Vaginal Swab</td>
                            <td>Same Day</td>
                            <td>Genexpert</td>
                          </tr>
                          <tr>
                            <td>Wet Smear / Gram Stain</td>
                            <td>Vaginal Swab</td>
                            <td>Same Day</td>
                            <td>Gram’s Method / Microscopy</td>
                          </tr>
                          <tr>
                            <td>Water for bacteriological testing</td>
                            <td>Water</td>
                            <td>1 Week</td>
                            <td>HI Water testing kit</td>
                          </tr>
                          <tr>
                            <td>Widal</td>
                            <td>Blood</td>
                            <td>Next Day</td>
                            <td>Tube Method / manual</td>
                          </tr>
                          <tr>
                            <td>Rapid Plasma Reagin</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>RPR Card Test / Manual</td>
                          </tr>
                          <tr>
                            <td>Infectious Mononucleosis</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>Latex Agglutination Test / Manual</td>
                          </tr>
                          <tr>
                            <td>Anti sperm Antibodies</td>
                            <td>Serum</td>
                            <td>2 Days</td>
                            <td>Enzyme linked immunosorbent assay (ELISA )</td>
                          </tr>
                          <tr>
                            <td>A S O Titre</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>Immuno Turbidimetric Assay/Fusion</td>
                          </tr>
                          <tr>
                            <td>CRP Quantitative</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>Immuno Turbidimetric Assay /Fusion</td>
                          </tr>
                          <tr>
                            <td>RA Factor Quantitative</td>
                            <td>Serum</td>
                            <td>Same Day</td>
                            <td>Immuno Turbidimetric Assay /Fusion</td>
                          </tr>
                          <tr>
                            <td>Quantiferon -TB Gold</td>
                            <td>Serum</td>
                            <td>7 Days</td>
                            <td>
                              Enzyme linked immunosorbent assay (ELISA) &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td>H. Pylori</td>
                            <td>Stool</td>
                            <td>1 Day</td>
                            <td>Immunochromatographic / Manual</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="nk__tab_content">
                    <h4>Clinical Pathology</h4>
                    <div className="table-responsive mb-5">
                      <table
                        class="table table-bordered table-hover"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <th width="25%">Test</th>
                            <th width="25%">Specimen Required</th>
                            <th width="10%">Report</th>
                            <th>Method/Instrument</th>
                          </tr>
                          <tr>
                            <td>
                              Fluid Routine exam including counts and excluding
                              microbiologic and biochemical examination
                            </td>
                            <td>
                              Peritoneal / Pericardial / Pleural / CSF /
                              Synovial
                            </td>
                            <td>Next Day</td>
                            <td>Microscopic</td>
                          </tr>
                          <tr>
                            <td>Malaria Parasite/Microfilaria</td>
                            <td>Blood</td>
                            <td>Same Day</td>
                            <td>Microscopy</td>
                          </tr>
                          <tr>
                            <td>Post Coital Test</td>
                            <td>Sample from Endo Cervical canal</td>
                            <td>Same Day</td>
                            <td>Microscopy</td>
                          </tr>
                          <tr>
                            <td>Pregnancy Test</td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>Paper Chromatography</td>
                          </tr>
                          <tr>
                            <td>
                              Protein Electrophoresis / Immunofixation
                              Electrophoresis
                            </td>
                            <td>Serum</td>
                            <td>7 Days</td>
                            <td>Agarose Gel / SEBIA</td>
                          </tr>
                          <tr>
                            <td>Rotavirus</td>
                            <td>Faeces</td>
                            <td>Same Day</td>
                            <td>Immonoassay / Manual</td>
                          </tr>
                          <tr>
                            <td>Semen Analysis</td>
                            <td>Semen</td>
                            <td>Same Day</td>
                            <td>Microscopic</td>
                          </tr>
                          <tr>
                            <td>Stool Occult Blood</td>
                            <td>Stool</td>
                            <td>Same Day</td>
                            <td>Guaiac Method</td>
                          </tr>
                          <tr>
                            <td>Urine Bence Jones Proteins</td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>Heat test</td>
                          </tr>
                          <tr>
                            <td>Urine BP (Bilirubin)</td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>Fouchet Method / Strip /&nbsp;Laura - M</td>
                          </tr>
                          <tr>
                            <td>Urine Ketones</td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>Rothera`s Test / Strip/&nbsp;Laura - M</td>
                          </tr>
                          <tr>
                            <td>
                              Urine Routine - except microbiological &amp;
                              biochemicals
                            </td>
                            <td>Urine</td>
                            <td>Same Day</td>
                            <td>Strip / Microscopic</td>
                          </tr>
                          <tr>
                            <td>Faecal Calprotectin</td>
                            <td>Stool</td>
                            <td>7 Days</td>
                            <td>Immunochromatographic Assay</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="nk__tab_content">
                    <h4>Cytopathology</h4>
                    <div className="table-responsive mb-5">
                      <table
                        class="table table-bordered table-hover"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <th>Test Name</th>
                            <th>Turnaround Time (TAT)</th>
                          </tr>
                          <tr>
                            <td>
                              Body Fluids-Cell counts and Cell Type (Synovial
                              fluid/Pleural fluid/CSF/Sputum/Urine/BAL fluid)
                            </td>
                            <td>Next day</td>
                          </tr>
                          <tr>
                            <td>PAP Smear - Conventional</td>
                            <td>2 Days</td>
                          </tr>
                          <tr>
                            <td>PAP LBC</td>
                            <td>2 Days</td>
                          </tr>
                          <tr>
                            <td>FNAC – Direct needle aspiration</td>
                            <td>2 Days</td>
                          </tr>
                          <tr>
                            <td>USG/CT guided FNAC</td>
                            <td>2 Days</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h4>Histopathology</h4>
                    <div className="table-responsive mb-5">
                      <table
                        class="table table-bordered table-hover"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <th>Test Name</th>
                            <th>Turnaround Time (TAT)</th>
                          </tr>
                          <tr>
                            <td>Small Biopsy</td>
                            <td>4-5 days</td>
                          </tr>
                          <tr>
                            <td>Medium Biopsy/Specimen</td>
                            <td>1 week</td>
                          </tr>
                          <tr>
                            <td>Large Specimen</td>
                            <td>1 week</td>
                          </tr>
                          <tr>
                            <td>Skin Biopsy</td>
                            <td>4-5 days</td>
                          </tr>
                          <tr>
                            <td>Skin Biopsy with fluorescence</td>
                            <td>1 week</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LaboratoriesPage;
