import React, { useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import useAxios from "../../custom-hooks/useAxios";
import axios from "../../services/apis/healthPackagesApi";
import Loader from "../../components/common/Loader/Loader";
import HealthPackageCard from "../../components/common/HealthPackageCard/HealthPackageCard";

import "./HealthPackagesPage.scss";

const API_ID =
  "AKfycbwR0phS094quQjeEjpKg_vU6TfACzMcGRaAkjsboMUWGvGPVHZDWboRez0t_nUwoPjR/exec";

const HealthPackagesPage = () => {
  const [loading, response, error, axiosFetch] = useAxios();

  useEffect(() => {
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `/${API_ID}`,
    });
  }, [axiosFetch]);

  return (
    <div className="nk__health-packages-page ptb-70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="section-title">
              <h2>Health Packages</h2>
            </div>
          </Col>
          <Col md={12}>
            <div className="nk__health-packages">
              <Row className="g-4">
                {loading && !error && (
                  <Col md={12}>
                    <Loader info="Loading Health Packages. Please wait..." />
                  </Col>
                )}

                {error && !loading && (
                  <Col md={12}>
                    <p>{error}</p>
                    <Loader />
                  </Col>
                )}
                {response &&
                  response?.map((p, i) => {
                    return (
                      <HealthPackageCard
                        data={p}
                        isLast={i === response.length - 1}
                        key={p.id}
                      />
                    );
                  })}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HealthPackagesPage;
