import { useCallback, useEffect, useMemo, useState } from "react";

const useIntersect = (ref, options) => {
  const [isVisible, setIsVisible] = useState(false);

  const opts = useMemo(() => options, [options]);

  const cb = useCallback((entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIsVisible(entry.isIntersecting);
    }
  }, []);

  useEffect(() => {
    const nodeRef = ref.current;

    if (!nodeRef) return;
    const observer = new IntersectionObserver(cb, opts);
    observer.observe(nodeRef);

    return () => {
      nodeRef && observer.unobserve(nodeRef);
    };
  }, [cb, opts, ref]);

  return [ref, isVisible];
};

export default useIntersect;
