import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const LiverFibroScanPage = () => {
  return (
    <React.Fragment>
      <div className="nk__qualityAssurance ptb-70 nk__radiology_page nk__faclities_sub-page">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Liver FibroScan</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="nk__tab_content">
                <p className="nk__content_title">What is “FibroScan”?</p>
                <p>
                  FibroScan is a specialized machine to scan liver to measure
                  Fatty Infiltration (steatosis) and Fibrosis in the liver. This
                  helps to understand liver parenchymal disease. Fibroscan may
                  be done alongwith other tests to evaluate liver disease, which
                  includes LFT, Ultrasound, Triple Phase CT of the abdomen and
                  Liver Biopsy.
                </p>
                <p>
                  Liver parenchymal disease may be silent and largely
                  under-diagnosed in a vast majority of population. Damage to
                  the hepatic parenchyma caused by liver disease can often be
                  reversed by early diagnosis and intervention.
                </p>
                <p>
                  FibroScan is a non-invasive, ultrasound based diagnostic test
                  for comprehensive management of liver health in patients with
                  liver disease. It is used to measure liver fibrosis & fatty
                  infiltration (steatosis) caused by liver disease.
                </p>
                <p className="nk__content_title">Indications of Fibroscan</p>
                <ul style={{ marginBottom: "2rem" }}>
                  <li>
                    Non-Alcoholic Fatty Liver Disease (NAFLD) & Non-Alcoholic
                    Steato Hepatitis (NASH)
                  </li>
                  <li>Viral hepatitis (Hepatitis B & C)</li>
                  <li>Long Standing Alcohol use</li>
                  <li>Obesity / Diabetes Mellitus</li>
                </ul>
                <p className=" mb-5">
                  FibroScan is based on the Vibration Controlled Transient
                  Elastography (VCTE) & Controlled Attenuation Parameter (CAP)
                  technology
                </p>
                <p className="nk__content_title text-center nk__block mb-5">
                  INTERPRETATION OF RESULTS:
                </p>
                <p className="nk__content_title">What is “CAP Score”?</p>
                <p>
                  Controlled Attenuation Parameter (CAP) score is a{" "}
                  <strong>
                    measurement of Fatty Infiltration in the liver to further
                    grade its degree
                  </strong>
                  . The CAP score ranges from 100 to 400 decibels per meter
                  (dB/m). The table below categorizes the CAP scores into a
                  certain steatosis grade and the range of percentage of fatty
                  change.
                </p>
                <div class="table-responsive mb-5">
                  <table
                    class="table table-bordered table-hover"
                    cellspacing="0"
                    cellpadding="0"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <th width="25%">CAP Score (dB/m)</th>
                        <th width="25%">Steatosis Grade</th>
                        <th width="25%">% of Liver with Fatty Change</th>
                      </tr>
                      <tr>
                        <td>≤ 237 </td>
                        <td>S0</td>
                        <td>≤ 10</td>
                      </tr>
                      <tr>
                        <td>238 – 260</td>
                        <td>S1</td>
                        <td>11 – 33</td>
                      </tr>
                      <tr>
                        <td>261 – 292</td>
                        <td>S2</td>
                        <td>34 - 66</td>
                      </tr>
                      <tr>
                        <td>≥ 293</td>
                        <td>S3</td>
                        <td>≥ 67</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="nk__content_title">What is “Fibrosis Result”?</p>
                <p>
                  Liver Stiffness Measurement (LSM) is a quantification of the
                  amount of liver Fibrosis (scarring) by measuring the stiffness
                  or elasticity (E) of liver parenchyma. The Mean E or LSM of
                  liver parenchyma ranges from 1.5 to 6 kPa (kilopascals), while
                  the highest possible result is 75 kPa. 6 to 7 kPa is
                  indeterminate range. Those who have liver abnormalities will
                  have a higher staging of fibrosis than normal.
                </p>
                <div class="table-responsive mb-5">
                  <table
                    class="table table-bordered table-hover"
                    cellspacing="0"
                    cellpadding="0"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <th width="25%">LSM / E Score (kPa)</th>
                        <th width="25%">Stage</th>
                        <th width="25%">Severity</th>
                      </tr>
                      <tr>
                        <td>{"<"} 6.0 – 7.0</td>
                        <td>F0</td>
                        <td>Absence of Fibrosis</td>
                      </tr>
                      <tr>
                        <td>7.1 – 10.0</td>
                        <td>F1</td>
                        <td>Mild Fibrosis</td>
                      </tr>
                      <tr>
                        <td>10.1 – 13.0</td>
                        <td>F2</td>
                        <td>Moderate Fibrosis</td>
                      </tr>
                      <tr>
                        <td>13.1 – 16.0</td>
                        <td>F3</td>
                        <td>Severe Fibrosis</td>
                      </tr>
                      <tr>
                        <td>{">"}16.1</td>
                        <td>F4</td>
                        <td>Cirrhosis</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="nk__content_title">
                  How accurate are FibroScan results?
                </p>
                <p>
                  As compared to Conventional Liver Elastography done on regular
                  Ultrasound Machines by most, the FibroScan is a step further,
                  <strong> also provides precise CAP score</strong>, not usually
                  available with Ultrasound Elastography. The results are very
                  reliable, considered to be{" "}
                  <strong>
                    Gold Standard for quantification of Fatty Infiltration and
                    Fibrosis both
                  </strong>
                  .
                </p>
                <p className="nk__content_title">Limitations of the Test</p>
                <p>The results may be less accurate if there is:</p>
                <ul style={{ marginBottom: "2rem" }}>
                  <li>
                    Acute Liver inflammation, either caused by recent liver
                    illness or drinking alcohol
                  </li>
                  <li>Liver congestion in heart failure</li>
                  <li>Extreme Obesity (BMI) higher than 30</li>
                  <li>Ascites</li>
                  <li>Biliary obstruction</li>
                  <li>Scar tissue from surgery or radiation</li>
                </ul>
                {/*                 
                <p>
                  <img
                    src="/images/facilities/stages-liver-damage.jpeg"
                    className="img-fluid liver_damage_stages_img"
                    alt="stages-liver-damage"
                  />
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LiverFibroScanPage;
