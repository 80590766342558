import React from "react";
import { Spinner } from "react-bootstrap";

import "./Loader.scss";

const Loader = ({ info }) => {
  return (
    <div className="loadingSpinner">
      <Spinner animation="border" role="status"></Spinner>
      <span className="loadingSpinner__info">{info}</span>
    </div>
  );
};

export default Loader;
