import React from "react";
import OwlCarousel from "react-owl-carousel3";

const options = {
  items: 1,
  loop: true,
  margin: 0,
  nav: true,
  dots: true,
  smartSpeed: 1000,
  autoplay: false,
  autoplayHoverPause: false,
  navText: [
    "<i class='icofont-simple-left'></i>",
    "<i class='icofont-simple-right'></i>",
  ],
};

const HeroSlider = () => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <React.Fragment>
      {display ? (
        <OwlCarousel
          className="home-slider owl-carousel owl-theme"
          {...options}
        >
          <div className="slider-item slider-item-img">
            <div className="container nk_banner_wrap">
              <div className="row align-items-center">
                <div className="col-md-12" id="fibroscan_slide">
                  <img
                    src="images/home-one/banner_10_img.png"
                    alt="Shape"
                    className="nk_banner_img img-fluid"
                  />
                  <div className="nk_banner_content">
                    <p
                      className="banner__subtitle"
                      style={{ textTransform: "uppercase" }}
                    >
                      World's Best Machine by Echosens
                    </p>
                    <p className="banner__title">FIBROSCAN</p>
                    <p>
                      Non invasive, painless & quick ultrasound specialised to
                      assess liver fibrosis & steatosis
                    </p>
                    <p>Benefit from FibroScan?</p>
                    <ul
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        paddingLeft: "0",
                        marginTop: "5px",
                      }}
                    >
                      <li className="col-md-6">
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Cirrhosis
                      </li>
                      <li className="col-md-6">
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Obesity
                      </li>
                      <li className="col-md-6">
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Fatty Liver Disease
                      </li>
                      <li className="col-md-6">
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Chronic Hepatitis B & C
                      </li>
                      <li className="col-md-6">
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Alcoholic Liver Disease
                      </li>
                      <li className="col-md-6">
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Other Liver Problems
                      </li>
                    </ul>
                    <p className="nk_appointment_button_wrap">
                      <a
                        href="https://wa.me/919643106760"
                        target="_blank"
                        rel="noreferrer"
                        className="nk_appointment_button"
                      >
                        Book Appointment
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-item slider-item-img">
            <div className="container nk_banner_wrap">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <img
                    src="images/home-one/banner_1_img.png"
                    alt="Shape"
                    className="nk_banner_img img-fluid"
                  />
                  <div className="nk_banner_content">
                    <p className="banner__title">3.OT MRI</p>
                    <p className="banner__subtitle">
                      powered by latest Multi-Transmit Technology
                    </p>
                    <ul>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Enhanced Contrast Uniformity & Consistency
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Enhanced Speed & Faster MRI
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Patient-Friendly Flared-bore Magnet with Significantly
                        Less Claustrophobia
                      </li>
                    </ul>
                    <p className="nk_appointment_button_wrap">
                      <a
                        href="https://wa.me/919643106760"
                        target="_blank"
                        rel="noreferrer"
                        className="nk_appointment_button"
                      >
                        Book Appointment
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-item slider-item-img">
            {/* <div className="d-table">
              <div className="d-table-cell">
                <a href="https://wa.me/919643106760" target="_blank"
                rel="noreferrer">
                  <img src="images/home-one/banner-2.png" alt="Shape" />
                </a>
              </div>
            </div> */}

            <div className="container nk_banner_wrap">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <img
                    src="images/home-one/banner_2_img.png"
                    alt="Shape"
                    className="nk_banner_img img-fluid"
                  />
                  <div className="nk_banner_content">
                    <p className="banner__title">64 detector</p>
                    <p className="banner__subtitle">
                      row volume ct with coronary angiography
                    </p>
                    {/* <p className="banner__subtitle2">
                      with coronary angiography
                    </p> */}
                    <ul>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Scans Faster
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Delivers High Image Quality For Accurate Diagnosis
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Maximizes Safety
                      </li>
                    </ul>
                    <p className="banner__subtitle2 banner__subtitle3">
                      We know protection from heavy radiation is most important
                    </p>
                    <p className="nk_appointment_button_wrap">
                      <a
                        href="https://wa.me/919643106760"
                        target="_blank"
                        rel="noreferrer"
                        className="nk_appointment_button"
                      >
                        Book Appointment
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img">
            {/* <div className="d-table">
              <div className="d-table-cell">
                <a href="https://wa.me/919643106760" target="_blank"
                rel="noreferrer">
                  <img src="images/home-one/banner-3.png" alt="Shape" />
                </a>
              </div>
            </div> */}
            <div className="container nk_banner_wrap">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <img
                    src="images/home-one/banner_3_img.png"
                    alt="Shape"
                    className="nk_banner_img img-fluid"
                  />
                  <img
                    src="images/home-one/banner_3_img_1.png"
                    alt="Shape"
                    className="nk_banner_img_sub img-fluid"
                  />
                  <div className="nk_banner_content">
                    <p className="banner__title">pink alert</p>

                    <ul>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Breast MRI
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        3D Mammography & Breast Ultrasound
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Image-Guided FNAC
                      </li>
                    </ul>
                    <p className="banner__subtitle2 banner__subtitle3">
                      IN YOUR "BREAST" INTEREST!
                    </p>
                    <p className="nk_appointment_button_wrap">
                      <a
                        href="https://wa.me/919643106760"
                        target="_blank"
                        rel="noreferrer"
                        className="nk_appointment_button"
                      >
                        Book Appointment
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img">
            {/* <div className="d-table">
              <div className="d-table-cell">
                <a href="https://wa.me/919643106760" target="_blank"
                rel="noreferrer">
                  <img src="images/home-one/banner-4.png" alt="Shape" />
                </a>
              </div>
            </div> */}
            <div className="container nk_banner_wrap">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <img
                    src="images/home-one/banner_4_img.png"
                    alt="Shape"
                    className="nk_banner_img img-fluid"
                  />
                  <div className="nk_banner_content">
                    <p className="banner__title">Covid-19 RT-PCR</p>

                    <ul>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Quick Precise Reports
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Reporting as per ICMR guidelines
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Home Collection Available
                      </li>
                    </ul>

                    <p className="nk_appointment_button_wrap">
                      <a
                        href="https://wa.me/919643106760"
                        target="_blank"
                        rel="noreferrer"
                        className="nk_appointment_button"
                      >
                        Book Appointment
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img">
            {/* <div className="d-table">
              <div className="d-table-cell">
                <a href="https://wa.me/919643106760" target="_blank"
                rel="noreferrer">
                  <img src="images/home-one/banner-5.png" alt="Shape" />
                </a>
              </div>
            </div> */}
            <div className="container nk_banner_wrap">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <img
                    src="images/home-one/banner_5_img.png"
                    alt="Shape"
                    className="nk_banner_img img-fluid"
                  />
                  <div className="nk_banner_content">
                    <p className="banner__title">Tests for COVID-19</p>

                    <ul>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        CBNAAT
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        ANTIBODY IgG
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Rapid Antigen
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        COVID Basic Panel
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        COVID Advanced Panel
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        COVID Extended Panel
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        IL-6 Monitoring Panel
                      </li>
                    </ul>

                    <p className="nk_appointment_button_wrap">
                      <a
                        href="https://wa.me/919643106760"
                        target="_blank"
                        rel="noreferrer"
                        className="nk_appointment_button"
                      >
                        Book Appointment
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img">
            {/* <div className="d-table">
              <div className="d-table-cell">
                <a href="https://wa.me/919643106760" target="_blank"
                rel="noreferrer">
                  <img src="images/home-one/banner-6.png" alt="Shape" />
                </a>
              </div>
            </div> */}
            <div className="container nk_banner_wrap">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <img
                    src="images/home-one/banner_6_img.png"
                    alt="Shape"
                    className="nk_banner_img img-fluid"
                  />
                  <div className="nk_banner_content">
                    <p className="banner__title">FETAL medicine</p>

                    <ul>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        NTNB/ Anomaly Scan
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        3D/4D Ultrasound
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Fetal Color Doppler
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Fetal ECHO
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Fetal MRI
                      </li>
                    </ul>

                    <p className="nk_appointment_button_wrap">
                      <a
                        href="https://wa.me/919643106760"
                        target="_blank"
                        rel="noreferrer"
                        className="nk_appointment_button"
                      >
                        Book Appointment
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img">
            {/* <div className="d-table">
              <div className="d-table-cell">
                <a href="https://wa.me/919643106760" target="_blank"
                rel="noreferrer">
                  <img src="images/home-one/banner-7.png" alt="Shape" />
                </a>
              </div>
            </div> */}
            <div className="container nk_banner_wrap">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <img
                    src="images/home-one/banner_7_img.png"
                    alt="Shape"
                    className="nk_banner_img img-fluid"
                  />
                  <div className="nk_banner_content">
                    <p className="banner__title">HIGH RESOLUTION ULTRASOUND</p>

                    <ul>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Color/Power Doppler
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        3D/4D Ultrasound
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        USG Guided Procedures
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Fetal MRI
                      </li>
                    </ul>

                    <p className="nk_appointment_button_wrap">
                      <a
                        href="https://wa.me/919643106760"
                        target="_blank"
                        rel="noreferrer"
                        className="nk_appointment_button"
                      >
                        Book Appointment
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img">
            {/* <div className="d-table">
              <div className="d-table-cell">
                <a href="https://wa.me/919643106760" target="_blank"
                rel="noreferrer">
                  <img src="images/home-one/banner-8.png" alt="Shape" />
                </a>
              </div>
            </div> */}
            <div className="container nk_banner_wrap">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <img
                    src="images/home-one/banner_8_img.png"
                    alt="Shape"
                    className="nk_banner_img img-fluid"
                  />

                  <div className="nk_banner_content">
                    <p className="banner__title">NABL ACcredited</p>
                    <p className="banner__subtitle">
                      Fully Automated Path labs
                    </p>

                    <ul>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Get Reminders
                      </li>
                      <li>
                        <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />{" "}
                        Access Your Test Results Anywhere
                      </li>
                    </ul>
                    <p className="banner__subtitle2 banner__subtitle3">
                      A one stop facility for all your diagnostic needs
                    </p>
                    <p className="nk_appointment_button_wrap">
                      <a
                        href="https://wa.me/919643106760"
                        target="_blank"
                        rel="noreferrer"
                        className="nk_appointment_button"
                      >
                        Book Appointment
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img">
            {/* <div className="d-table">
              <div className="d-table-cell">
                <a href="https://wa.me/919643106760" target="_blank"
                rel="noreferrer">
                  <img src="images/home-one/banner-9.png" alt="Shape" />
                </a>
              </div>
            </div> */}
            <div className="container nk_banner_wrap">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <img
                    src="images/home-one/banner_9_img.png"
                    alt="Shape"
                    className="nk_banner_img img-fluid"
                  />

                  <div className="nk_banner_content">
                    <p className="banner__title">
                      Book your family checkup online
                    </p>

                    <p className="nk_appointment_button_wrap">
                      <a
                        href="https://wa.me/919643106760"
                        target="_blank"
                        rel="noreferrer"
                        className="nk_appointment_button"
                      >
                        Book Appointment
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="slider-item slider-item-img">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="slider-text">
                    <div className="slider-shape">
                      <img src="images/home-one/banner-1.png" alt="Shape" />
                    </div>

                    <h1>
                      3.0T MRI <span>Powered By Latest</span>
                      <span>Multi-Transmit Technology</span>
                    </h1>

                    <ul>
                      <li>
                        <i className="icofont-hand-right"></i> Enhanced Contrast
                        Uniformity & Consistency
                      </li>
                      <li>
                        <i className="icofont-hand-right"></i> Enhanced Speed &
                        Faster MRI
                      </li>
                      <li>
                        <i className="icofont-hand-right"></i> Patient-Friendly
                        Flared-bore Magnet with Significantly Less
                        Claustrophobia
                      </li>
                    </ul>

                    <div className="common-btn">
                      <Link href="/">
                        <a>
                          Get Appointment{" "}
                          <img
                          src="images/home-one/l-arrow.png"
                          alt="Shape"
                          className="img-fluid l-arrow"
                        />
                        </a>
                      </Link>
                      <Link href="/">
                        <a className="cmn-btn-right">
                          Learn More{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img slider-2">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="slider-text">
                    <div className="slider-shape-two">
                      <img src="images/home-one/banner-2.png" alt="Shape" />
                    </div>
                    <h1>
                      64 DETECTER ROW
                      <br />
                      VOLUME CT
                      <span>With Coronary Angiography</span>
                    </h1>
                    <p>
                      We know protection from heavy radiant is most important
                    </p>
                    <ul>
                      <li>
                        <i className="icofont-hand-right"></i> Scans Faster
                      </li>
                      <li>
                        <i className="icofont-hand-right"></i> Deliver High
                        Image Quality For Accurate Diagnoses
                      </li>
                      <li>
                        <i className="icofont-hand-right"></i> Maximizes Safety
                      </li>
                    </ul>

                    <div className="common-btn">
                      <Link href="/">
                        <a>
                          Get Appointment{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                      <Link href="/">
                        <a className="cmn-btn-right">
                          Learn More{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="slider-text">
                    <div className="slider-shape-three">
                      <img src="images/home-one/banner-3.png" alt="Shape" />
                    </div>
                    <h1>
                      Book Family Health
                      <br />
                      Checkup online
                    </h1>
                    <p>
                      <i className="icofont-hand-right"></i> We Offer Highly
                      Treatments
                    </p>

                    <div className="common-btn">
                      <Link href="/">
                        <a>
                          Get Appointment{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                      <Link href="/">
                        <a className="cmn-btn-right">
                          Learn More{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img slider-5">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="slider-text">
                    <div className="slider-shape-five">
                      <img src="images/home-one/banner-4.png" alt="Shape" />
                    </div>
                    <h1>
                      NABL ACCREDITED
                      <span>Fully Automated Path labs</span>
                    </h1>
                    <ul>
                      <li>
                        <i className="icofont-hand-right"></i> Get Reminders
                      </li>
                      <li>
                        <i className="icofont-hand-right"></i> Access Your Test
                        Results Anywhere
                      </li>
                    </ul>
                    <p>
                      MAKING US A ONE STOP FACILITY FOR ALL YOUR DIAGNOSTIC
                      NEEDS
                    </p>

                    <div className="common-btn">
                      <Link href="/">
                        <a>
                          Get Appointment{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                      <Link href="/">
                        <a className="cmn-btn-right">
                          Learn More{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="slider-text">
                    <div className="slider-shape-four">
                      <img src="images/home-one/banner-5.png" alt="Shape" />
                    </div>
                    <h1>
                      Looking For
                      <br />
                      COVID- 19 test?
                    </h1>

                    <div className="common-btn">
                      <Link href="/">
                        <a>
                          Get Appointment{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                      <Link href="/">
                        <a className="cmn-btn-right">
                          Learn More{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img slider-6">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="slider-text">
                    <div className="slider-shape-six">
                      <img
                        src="images/home-one/home-slider7.png"
                        alt="Shape"
                      />
                      <img
                        src="images/home-one/home-slider6.png"
                        alt="Shape"
                      />
                    </div>
                    <h1>PINK ALERT</h1>
                    <ul>
                      <li>
                        <i className="icofont-hand-right"></i> Breast MRI
                      </li>
                      <li>
                        <i className="icofont-hand-right"></i> Digital
                        Mammography & Breast Ultrasound
                      </li>
                      <li>
                        <i className="icofont-hand-right"></i> Image Guided FNAC
                      </li>
                    </ul>
                    <p>IN YOUR "BREAST" INTEREST!</p>

                    <div className="common-btn">
                      <Link href="/">
                        <a>
                          Get Appointment{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                      <Link href="/">
                        <a className="cmn-btn-right">
                          Learn More{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item slider-item-img slider-7">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="slider-text">
                    <div className="slider-shape-seven">
                      <img src="images/home-one/banner-6.png" alt="Shape" />
                    </div>
                    <h1>PINK ALERT</h1>
                    <ul>
                      <li>
                        <i className="icofont-hand-right"></i> Breast MRI
                      </li>
                      <li>
                        <i className="icofont-hand-right"></i> Digital
                        Mammography & Breast Ultrasound
                      </li>
                      <li>
                        <i className="icofont-hand-right"></i> Image Guided FNAC
                      </li>
                    </ul>
                    <p>IN YOUR "BREAST" INTEREST!</p>

                    <div className="common-btn">
                      <Link href="/">
                        <a>
                          Get Appointment{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                      <Link href="/">
                        <a className="cmn-btn-right">
                          Learn More{" "}
                          <i className="icofont-rounded-double-right"></i>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </OwlCarousel>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default HeroSlider;
