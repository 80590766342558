import React from "react";

const RuhaniDodaKheraDetailsPage = () => {
  return (
    <div className="doctor-details-area ptb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="doctor-details-item doctor-details-left">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-12">
                  <img src="/images/dr-ruhani-doda.png" alt="Doctor" />
                </div>
                <div className="col-md-6 col-lg-12">
                  <div className="doctor-details-contact">
                    <h3>Contact info</h3>
                    <ul>
                      <li>
                        <i className="icofont-ui-call"></i>
                        <a href="tel:+919643106759">Call: +91 9643106759</a>
                      </li>
                      <li>
                        <i className="icofont-ui-message"></i>
                        <a href="mailto:dodahealthcare@gmail.com">
                          dodahealthcare@gmail.com
                        </a>
                      </li>
                      <li>
                        <i className="icofont-location-pin"></i>
                        23-B, Pusa Road, Opposite Metro Pillar 115, New Delhi -
                        110005, India.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="doctor-details-work">
                  <h3>Working hours</h3>
                  <div className="appointment-item-two-right">
                    <div className="appointment-item-content">
                      <ul>
                        <li>
                          Monday <span>9:00 AM - 8:00 PM</span>
                        </li>
                        <li>
                          Tuesday <span>9:00 AM - 8:00 PM</span>
                        </li>
                        <li>
                          Wednesday <span>9:00 AM - 8:00 PM</span>
                        </li>
                        <li>
                          Sunday <span>9:00 AM - 8:00 PM</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
            </div>
          </div>

          <div className="col-lg-7">
            <div className="doctor-details-item">
              <div className="doctor-details-right">
                <div className="doctor-details-biography">
                  <h3>DR. RUHANI DODA KHERA</h3>
                  <p>ASSOCIATE DIRECTOR & CONSULTANT</p>
                </div>

                <div className="doctor-details-biography">
                  <p>
                    Dr. Ruhani Doda Khera completed her medical school from
                    Chaudhary Charan Singh University, Meerut, India followed by
                    post graduate residency training (Radio-Diagnosis), National
                    Board of Examinations from Sir Ganga Ram Hospital, New Delhi
                    in 2012. She joined as a Radiologist with administrative
                    responsibilities at Dr. Doda’s Diagnostics & Healthcare, New
                    Delhi. She has been instrumental in setting up Bone Mineral
                    Densitometry and Mammography for promoting health screening
                    in women followed by operationalizing the state-of-the-art
                    Cone Beam CT for Dental and ENT imaging. She has notable
                    achievements in strategic operations in business expansion
                    keeping pace with the technological advancements in setting
                    up a 3T MRI and Multi Detector Row CT. She has also been
                    instrumental in getting our institution the highest
                    accreditations set by the Quality Council of India: National
                    Accreditation Board for Hospitals & Healthcare Providers
                    (NABH) and National Accreditation Board for Testing and
                    Calibration Laboratories (NABL).
                  </p>

                  <p>
                    During her post-doctoral fellowship (2017-2022) at the
                    prestigious Massachusetts General Hospital/ Harvard Medical
                    School, Boston, MA, USA, she specialized in quality and
                    safety, patient experience and applicability of artificial
                    intelligence in improving radiological diagnosis and
                    operational efficiency. She also holds a master’s in
                    business administration (MBA) from the University of
                    Massachusetts, Amherst. In addition, she is a graduate of
                    the Mass General Brigham Clinical Process Improvement
                    Leadership Program and now serves as a coach and invited
                    faculty for the same. She is also an inducted member of the
                    Phi Kappa Phi and Beta Gamma Sigma honor societies based on
                    her academic excellence in the MBA program. She has
                    published extensively and has numerous ongoing projects at
                    Massachusetts General Hospital, including clinical trials.
                    She presents her research at national and international
                    conferences and has won many accolades for her seminal work
                    in patient experience and quality and safety.
                  </p>

                  <p>
                    Currently, in addition to her role as Program Director for
                    Enterprise Radiology at Mass General Brigham, Dr. Ruhani
                    Doda Khera has valuable contribution towards ongoing
                    projects of the organization and implementing comprehensive
                    strategies that drive company and culture success. Over the
                    last 15 years, she has acquired a unique skillset of being
                    an imaging specialist, physician administrator and
                    healthcare entrepreneur. She is passionate to bring this
                    multinational experience to improve patient safety, quality
                    of care and efficiency in healthcare practice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RuhaniDodaKheraDetailsPage;
