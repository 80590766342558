import React, { useEffect } from "react";
import { Col, Row, Container, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import useAxios from "../../custom-hooks/useAxios";
import axios from "../../services/apis/healthPackagesApi";
import Loader from "../../components/common/Loader/Loader";

import "./HealthPackagesSlider.scss";

const API_ID =
  "AKfycbwR0phS094quQjeEjpKg_vU6TfACzMcGRaAkjsboMUWGvGPVHZDWboRez0t_nUwoPjR/exec";

const options = {
  items: 4,
  loop: true,
  margin: 15,
  nav: true,
  dots: true,
  smartSpeed: 1000,
  autoplay: true,
  autoplayHoverPause: true,
  stagePadding: 0,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsiveClass: false,
  responsive: {
    0: {
      items: 1,
      nav: true,
    },
    480: {
      items: 2,
      nav: true,
    },
    768: {
      items: 2,
      nav: true,
    },
    991: {
      items: 2,
      nav: true,
    },
    1000: {
      items: 4,
      nav: true,
      loop: false,
    },
  },
};

const HealthPackagesSlider = () => {
  const [loading, response, error, axiosFetch] = useAxios();

  useEffect(() => {
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `/${API_ID}`,
    });
  }, [axiosFetch]);

  return (
    <div className="nk__health-package-slider ptb-70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="section-title">
              <h2>Health Packages</h2>
            </div>
          </Col>

          <Col md={12}>
            <Row>
              {loading && !error && (
                <Col md={12}>
                  <Loader info="Loading Health Packages. Please wait..." />
                </Col>
              )}

              {error && !loading && (
                <Col md={12}>
                  <p>{error}</p>
                  <Loader />
                </Col>
              )}

              {response && response.length > 0 && (
                <Col md={12}>
                  <OwlCarousel className="owl-carousel owl-theme" {...options}>
                    {response?.map((p, i) => {
                      return (
                        <Link
                          to={`/health-package/${p.id}`}
                          className="nk__health-packages--card-link"
                          key={p.id}
                        >
                          <div className="health__package-slide">
                            <div className="nk__health-packages--card">
                              <Card>
                                <div className="nk__health-packages--card-img-wrap">
                                  <Card.Img
                                    variant="top"
                                    src={p.imgUrl}
                                    data-src={p.imgUrl}
                                    className="nk__health-packages--card-img"
                                  />
                                </div>
                                <Card.Body>
                                  <Card.Title className="nk__health-packages--card-title">
                                    {p.title}
                                  </Card.Title>
                                  <Card.Text className="nk__health-packages--card-tests">
                                    Tests Included: {p.testsCount}
                                  </Card.Text>
                                  <Button className="nk__health-packages--card-explore">
                                    Explore{" "}
                                    <i className="icofont-rounded-double-right"></i>
                                  </Button>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </OwlCarousel>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HealthPackagesSlider;
