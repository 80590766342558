import React from "react";

const GoTop = ({ scrollStepInPx, delayInMs }) => {
  const [thePosition, setThePosition] = React.useState(false);
  //   const timeoutRef = React.useRef(null);

  React.useEffect(() => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        setThePosition(true);
      } else {
        setThePosition(false);
      }
    });
  }, []);

  //   const onScrollStep = () => {
  //     if (window.pageYOffset === 0) {
  //       clearInterval(timeoutRef.current);
  //     }
  //     window.scroll(0, window.pageYOffset - scrollStepInPx);
  //   };

  //   const scrollToTop = () => {
  //     timeoutRef.current = setInterval(onScrollStep, delayInMs);
  //   };

  const renderGoTopIcon = () => {
    return (
      <React.Fragment>
        <div
          className={`go-top go-top-downloadReport ${
            thePosition ? "active" : ""
          }`}
        >
          <a href="tel:+919643106760">
            <i className="icofont-ui-call"></i>
          </a>
        </div>
        <div
          className={`go-top go-top-whatsapp ${thePosition ? "active" : ""}`}
        >
          <a href="https://wa.me/919643106760" target="_blank" rel="noreferrer">
            <i className="icofont-brand-whatsapp"></i>

            {/* <span>Lets Chat</span> */}
          </a>
        </div>
        {/* <div
          className={`go-top ${thePosition ? "active" : ""}`}
          onClick={scrollToTop}
        >
          <i className="icofont-hand-drawn-up"></i>
        </div> */}
      </React.Fragment>
    );
  };

  return <React.Fragment>{renderGoTopIcon()}</React.Fragment>;
};

export default GoTop;
