import axios from "axios";

const BASE_URL = "https://script.google.com/macros/s";

const healthPackagesApi = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  timeoutErrorMessage: "Request is taking too long to respond...",
});

healthPackagesApi.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

healthPackagesApi.interceptors.response.use(
  (res) => {
    return { ...res, data: res.data.data };
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default healthPackagesApi;
