import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { resetIdCounter, Tab, Tabs, TabList, TabPanel } from "react-tabs";

resetIdCounter();

const RadiologyPage = () => {
  return (
    <React.Fragment>
      <div className="nk__qualityAssurance bg-dark-grey ptb-70 nk__radiology_page nk__faclities_sub-page">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Radiology</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-12">
              <Tabs>
                <TabList>
                  <Tab>3.0T MRI with Multi Transmit Technology</Tab>
                  <Tab>64 Detector Row CT Scanner</Tab>
                  <Tab>Non Invasive CT-Angiography</Tab>
                  <Tab>3D-4D Ultrasound</Tab>
                  <Tab>Color Doppler</Tab>
                  <Tab>Digital X-Ray DR System</Tab>
                  <Tab>Cone Beam Dental CT,OPG</Tab>
                  <Tab>3D Digital Mammography</Tab>
                  <Tab>Bone Densitometry (DEXA)</Tab>
                </TabList>
                <TabPanel>
                  <div className="nk__tab_content">
                    <p>
                      The centre is equipped with the latest 3.0T MRI with Multi
                      Transmit Technology from Philips. All MRI examinations
                      including ultrafast sub-second scans are routinely
                      performed at the centre. MRI is a non invasive study using
                      nuclear magnetic resonance to render images of the inside
                      of the body to demonstrate pathological or other
                      physiological alterations of the living tissues.
                    </p>
                    <p>
                      MR imaging is based on the relaxation properties of
                      excited hydrogen nuclei in water and lipids when the
                      object to be imaged in are placed in powerful magnetic
                      field in form of a large cylindrical magnet. The spins of
                      atomic nuclei arrange in a particular manner parallel or
                      anti-parallel to the applied magnetic field. After this
                      orthogonal magnetic gradients are applied in X,Y & Z axis
                      to selectively image different volume picture elements of
                      the subject. This data is created in a 2D or 3D matrix and
                      images are created from this matrix.
                    </p>
                    <p>
                      MR imaging uses a powerful magnet, radiofrequency waves
                      and a computer to generate pictures of organs, soft
                      tissues, one and other internal body structures in detail.
                      No harmful ionizing radiation is used in MR imaging.
                    </p>

                    <p className="nk__content_title">
                      What is Multi Transmit MRI ?
                    </p>
                    <p>
                      <img
                        src="/images/facilities/mri-image.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </p>
                    <p className="nk__content_title">
                      The usual applications of MRI are in diagnosing /
                      visualizing :
                    </p>
                    <ul>
                      <li>Tumors or inflammatory granulomas in brain</li>
                      <li>Infections in the brain, spine or joints</li>
                      <li>Torn ligaments in the wrist, knee and ankle</li>
                      <li>Degenerative disease of spine and bulging disc</li>
                      <li>Shoulder injuries and tendonitis</li>
                      <li>Stroke in the earliest stage</li>
                      <li>Blood vessels</li>
                    </ul>
                    <p className="nk__content_title">Diffusion MRI</p>
                    <p>
                      Our MRI machine unlike other MRI machines produces images
                      comparable to PET Scan without the radiation.
                    </p>
                    <p>
                      Diffusion Weighted Imaging with Background Suppression
                      (DWIBS) is able to distinguish a tumor in normal looking
                      tissue. This is because unlike other MRI sequences, DWIBS
                      shows the relatively dense molecular structure of the
                      rapidly dividing tumor. This sequence was designed to
                      achieve images similar to a PET scan. Research has shown
                      DWIBS achieves a diagnostic confidence comparable to that
                      of a PET scan. A facility with this unique capability
                      plays an important role when choosing an imaging centre
                      that’s right for you.
                    </p>
                    <p>
                      Until recently, most MRI machines were unable to perform a
                      full body examination. They could only perform individual
                      parts where the study had to be stopped and the patient
                      repositioned in a piecemeal fashion. Our machine is one of
                      a few machines in the country that allows full body MRI
                      imaging from head to toe.
                    </p>
                    <p>
                      We perform a thorough study of the brain and entire spine,
                      with a complete systematic review of all of the abdominal
                      and pelvic organs. We believe in the central role of
                      comprehensive risk factor assessment and screening
                      technologies in the early detection of disease. After your
                      scan, our radiologists will review the examination with
                      you and provide you with a copy of the study results.
                    </p>
                    <p>
                      Diffusion weighted images are very commonly used in the
                      assessment of acute stroke and are used in oncology.
                      Diffusion Tensor Imaging , also known as MR Tractography
                      non-invasively maps white matter tracts.
                    </p>
                    <p className="nk__content_title">MR Angiography (MRA)</p>
                    <p>
                      MR angiography is used to generate images of the arteries
                      to detect any abnormal narrowing / dilatation or abnormal
                      arterio-venous connections. MR angiography is often used
                      to evaluate the arteries of the neck and brain, the
                      thoracic and renal arteries and the arteries of legs.
                      Magnetic Resonance Venography (MRV) procedure is used to
                      image veins.
                    </p>
                    <p className="nk__content_title">MR Spectroscopy</p>
                    <p>
                      MR spectroscopy is a noninvasive method and provides
                      metabolic information about the brain. MR spectroscopy
                      enables tissue characterization on a biochemical level
                      surpassing that of conventional MR imaging. MR
                      spectroscopy also detects abnormalities that are invisible
                      to conventional MRI because metabolic abnormalities often
                      precede structural changes.
                    </p>
                    <p className="nk__content_title">MR Mammography</p>
                    <p>
                      Although X-Ray Mammography remains the primary imaging
                      modality in the evaluation of breast disease, the
                      mammograms at times are inconclusive for the presence or
                      location of an abnormality. MR imaging is used as an
                      adjunct to mammography, particularly for patients with
                      equivocal mammographic findings. MR mammography is useful
                      to verify multifocality and multicentricity of breast
                      cancer, differentiate scars from recurrences after
                      breast-conserving therapy, screen high-risk groups who
                      have a family history of breast cancer, investigate breast
                      implants, examine breasts in cases of histologically
                      proven breast cancer and metastasis with unknown primary.
                    </p>
                    <p className="nk__content_title">MR Defecography</p>
                    <p>
                      MR defecography is a technique to study disorders of
                      ano-rectal function. It is indicated in patients having
                      constipation, rectal incontinence, painful defecation and
                      rectal prolapse. It permits analysis of the ano-rectal
                      angle, opening of the anal canal, function of the
                      puborectalis muscle and descent of pelvic floor during
                      defecation. It provides good demonstration of the rectal
                      wall, intussusception, enteroceles and rectoceles with
                      excellent demonstration of the perirectal soft tissue. It
                      allows assessment of spastic pelvic floor syndrome and
                      descending perineum syndrome.
                    </p>
                    <p className="nk__content_title text-center nk__block">
                      ANSWERING YOUR QUESTIONS ABOUT MRI
                    </p>
                    <p className="nk__content_title">What exactly is MRI?</p>
                    <p>
                      MRI stands for Magnetic Resonance Imaging. Without using
                      traditional X-ray imaging, MRI allows doctors to see
                      inside the body to diagnose and identify possible medical
                      conditions. An MRI simply samples signals from the water
                      that makes up your body. Specialized antennae create
                      highly defined images that can be used to make diagnosis.
                      You wll discover that MRI testing is painless and much
                      quicker than you think. In fact, you will probably be very
                      comfortable as you lie on the padded table. The accuracy
                      and speed of the latest MRI scanners means that you will
                      be done quickly and your doctor will have to run fewer—if
                      any—follow-up scans. But keep in mind that an MRI is not
                      for everyone. So be sure to inform your physician if you
                      have: a pacemaker, aneurysm clips in the brain, a shunt
                      with telesensor, inner ear implants, metal fragments in
                      one or both eyes, implanted spinal cord stimulators, or if
                      you’re pregnant or breast feeding. Many of your questions
                      about MRI are answered here. But should you have any other
                      questions or concerns, please consult your doctor.
                    </p>
                    <p className="nk__content_title">
                      MRI at Dr. Dodas – what is so special ?
                    </p>
                    <p>
                      Dr. Dodas diagnostics and healthcare is powered with
                      unique Patient-adaptive 3.0T MRI with MultiTransmit te c h
                      n o l o g y. T h i s te c h n o l o g y d e l i ve r s mo
                      s t homogenous images for accurate interpretation, in
                      contrast to conventional 3.0T MRIs where dielectric
                      shading is a major concern The ultra-high-field Achieva
                      3.0T TX system delivers faster scans with
                      higher-resolution which enables precise diagnosis. It is
                      wider opening and low noise features with dedicated coil
                      designs for each body part makes it ideal for patient
                      comfort and a positive MRI experience.
                    </p>
                    <p className="nk__content_title">
                      WHY ARE MRI SCANS IMPORTANT?
                    </p>
                    <p>
                      MRI scans allow doctors to see images of your internal
                      organs and structures in great detail from many angles.
                      This gives them information more quickly, and in many
                      cases more economically, than past tests and exploratory
                      surgeries.
                    </p>
                    <p className="nk__content_title">
                      IS AN MRI SCAN LIKE AN X-RAY?
                    </p>
                    <p>
                      No, an MRI scan uses a powerful magnet in conjunction with
                      radio frequency waves to generate images of your internal
                      organs and structures. It is one of the least invasive
                      tests that can see inside the body.
                    </p>
                    <p className="nk__content_title">
                      HOW LONG WILL THE EXAM TAKE?
                    </p>
                    <p>
                      That will depend on what is being studied, but a typical
                      exam lasts between 8 to 20 minutes. You should allow extra
                      time in case the exam lasts longer than expected.
                    </p>
                    <p className="nk__content_title">
                      DOES THE MACHINE MAKE A LOT OF NOISE?
                    </p>
                    <p>
                      The magnet makes a slight rapping sound as images are
                      being taken. In between scans the machine is quiet. The
                      MRI technologist will provide you with hearing protection,
                      but its use will not prevent you from hearing the
                      technologist if he or she speaks to you during the exam.
                    </p>
                    <p className="nk__content_title">
                      DO I HAVE TO HOLD STILL THE WHOLE TIME?
                    </p>
                    <p>
                      It is important for image clarity and the best scan
                      results to hold still during the exam. The technologists
                      will inform you when you may move between scans. Keep in
                      mind a routine exam can take at least 8 minutes.
                    </p>
                    <p className="nk__content_title">WILL I BE ALONE?</p>
                    <p>
                      You will be in contact with a technologist at all times.
                      Even when he or she is not in the MRI room, you will be
                      able to talk to him or her by intercom. In some cases a
                      family member is welcome to stay in the room with you
                      during your scan.
                    </p>
                    <p>
                      Magnetic Resonance Imaging is one of the most useful tools
                      physicians have to make an accurate diagnosis and select
                      an appropriate treatment for their patients. Because these
                      images are computer generated, they can be instantly sent
                      electronically to off-site specialists and consultants.
                      Thanks to advanced design features, your MRI should prove
                      perfectly comfortable. In fact, you find it more relaxing.
                    </p>
                    <p className="nk__content_title text-center nk__block">
                      INSTRUCTIONS FOR PATIENTS
                    </p>
                    <p className="nk__content_title">
                      We recommend you take prior appointment for MRI to avoid
                      waiting time at the centre.
                    </p>
                    <p>
                      Though in most of the examinations no contrast is used.
                      But some will require administration of contrast. This is
                      generally so when we suspect infective/inflammatory
                      pathology or mass lesion. Some times it is also
                      administered for vascular studies and other special
                      applications. Reports for kidney function such as blood
                      urea, serum creatinine or any other investigation that may
                      have been done for kidneys should be brought at the time
                      of examination. Any history of allergy to drugs, diabetes
                      and drugs that are being taken for treatment should be
                      available.
                    </p>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="nk__tab_content">
                    <p>
                      CT combines X-Rays with computer technology to create
                      accurate and detailed diagnostic images. Electronic
                      sensors help to detect the X-rays that pass through the
                      body to create images for in depth evaluation. All routine
                      CT scans are done using ultra-thin 0.625mm slices with
                      volume acquisition and isometric reconstruction in any
                      plane. 3D-CT examinations are routinely performed along
                      with virtual colonoscopy, virtual bronchoscopy and whole
                      body scanning. Contrary to popular belief, CT testing is
                      non-invasive, painless and relatively fast. Although CT
                      exam is one of the most regular medical imaging
                      procedures, it will be in your best interest to inform
                      your doctor if you are pregnant, asthmatic, diabetic and
                      taking metformin, breastfeeding or suffering from any
                      renal failure.
                    </p>
                    <p className="nk__content_title">
                      List of CT Scan Investigations
                    </p>
                    <ul>
                      <li>Head Plain &amp; Contrast</li>
                      <li>Sella &nbsp;With Head Routine</li>
                      <li>Orbit</li>
                      <li>HRCT Temporal Bone&nbsp;</li>
                      <li>HRCT PNS&nbsp;</li>
                      <li>Face/PNS&nbsp;</li>
                      <li>Face (3 D Scanning)</li>
                      <li>Neck/Larynx</li>
                      <li>Chest/Thorax</li>
                      <li>HRCT Lungs</li>
                      <li>Abdomen</li>
                      <li>Pelvis</li>
                      <li>KUB Region</li>
                      <li>Musculo - Skeletal (3 D Scanning)</li>
                      <li>Cervical/ Dorsal/ Lumbar Spine&nbsp;</li>
                      <li>Angiography</li>
                      <li>CT Guided FNAC</li>
                    </ul>
                    <p className="nk__content_title">
                      Brilliance CT at Dr. Dodas - what is so special ?
                    </p>
                    <p>
                      The Brilliance CT at Dr. Dodas Diagnostics & Healthcare is
                      designed to conduct the most advanced multi-slice CT
                      studies. This system enables you to achieve the highest
                      level of performance in neuro vascular, coronary artery,
                      pulmonary imaging and peripheral angiographic studies.
                    </p>
                    <ul>
                      <li>Fastest Scanning with highest image quality.</li>
                      <li>Non invasive cardiac scanning within few minutes.</li>
                      <li>Whole Body Angiography within seconds.</li>
                      <li>
                        Wide coverage CT perfusion imaging for stroke patients
                        using jog mode acquisitions.
                      </li>
                      <li>
                        Superior non invasive virtual colonoscopy to accurately
                        evaluate bowel diseases.
                      </li>
                      <li>
                        Lung imaging with Philips Ultra High Resolution Matrices
                        for HRCT Lung studies.
                      </li>
                      <li>
                        Brilliance Trauma imaging Protocols - Instant
                        Assessments of Trauma Patients.
                      </li>
                    </ul>
                    <p className="nk__content_title text-center nk__block">
                      We recommend prior appointment for CT Scan to avoid
                      waiting period at the centre.
                    </p>
                    <p className="nk__content_title">
                      Instructions for patients
                    </p>
                    <p>
                      Depending on the part of the body to be scanned, you will
                      be given instructions by your doctor. Unless told
                      otherwise, you can eat and drink normally before and after
                      the scan. However, if you are advised for a contrast
                      injected scan, you may require 4-6 hours fasting prior to
                      the examination. The CT scanning procedure usually lasts
                      for 5-10 minutes in which the actual exposure time is only
                      few seconds.
                    </p>
                    <p className="nk__content_title">
                      What is being injected for the scan and why?
                    </p>
                    <p>
                      You may need a contrast medium injection into your arm
                      before the scan. This is necessary to highlight certain
                      organs or vessels more clearly. However, with Philips
                      Brilliance CT the process is much safer as the injected
                      contrast dosage can be lowered significantly depending on
                      the kind of scan. You will be asked about any allergies
                      especially from iodine based products or shell fish and
                      any other medical conditions before the infusion. Contrast
                      is generally given when we suspect infective/inflammatory
                      pathology or mass lesion. It is also administered for
                      vascular studies and other special applications. Reports
                      for kidney function such as blood urea, serum creatinine
                      or any other investigation that may have been done for
                      kidneys should be brought at the time of examination.
                    </p>
                    <p>Upper abdomen and whole abdomen examinations</p>
                    <p>
                      It is essential to come with overnight fasting for these
                      examinations. Investigations will take 2-3 hrs or even
                      more to complete as oral contrast is generally given and
                      it takes time to reach colon. We normally do not ask
                      patient to come with full bladder as it will generally
                      fill during the course of administration of oral contrast.
                      However, you are required not to pass urine during this
                      examination.
                    </p>
                    <p className="nk__content_title">
                      KUB and lower abdomen examinations
                    </p>
                    <p>
                      There is no need of fasting for these examinations but
                      full bladder is a requirement. Further, avoid examination
                      immediately after taking food. A gap of 2 hrs is advisable
                      whenever contrast is to be administered.
                    </p>
                    <p className="nk__content_title">Head examinations</p>
                    <p>
                      Normally no preparation is required for these
                      examinations. But in case of children and those with
                      neurological problems, may need to be
                      sedated/anaesthetized as good examination can not be done,
                      if patient is moving his/her head.
                    </p>
                    <p className="nk__content_title">
                      Allergy to drugs and contrast media
                    </p>
                    <p>
                      Though we use non-ionic contrast media which has very few
                      reactions, however, a severe reaction is a possibility. If
                      you are allergic to any drug or contrast media, you must
                      tell us. This may require modification and different
                      planning for examination.
                    </p>
                    <p>Your Creatinine levels should be known.</p>
                    <p>
                      If you are diabetic, then the history of the drugs you
                      arer taking should be known
                    </p>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="nk__tab_content">
                    <p>
                      <strong>Computed Tomography Angiography</strong> (also
                      called CT angiography ) is a technique used to produce
                      detailed images of both blood vessels and tissues in
                      various parts of the body. An iodine-rich contrast
                      material (dye) is usually injected through a small cannula
                      placed in a vein of the arm before CT scan while the
                      contrast flows through the blood vessels to the various
                      organs of the body. An automatic injection pump connected
                      to the IV gives contrast material at a controlled rate.
                      After scanning, the images are processed using a special
                      computer and software and reviewed in different planes and
                      projections.
                    </p>
                    <p className="nk__content_title">
                      CT angiography is used to examine blood vessels and the
                      organs supplied by them in various body parts, including:
                    </p>
                    <ul>
                      <li>Brain</li>
                      <li>Neck</li>
                      <li>Heart</li>
                      <li>Chest</li>
                      <li>Abdomen (such as the kidneys and liver)</li>
                      <li>Pelvis</li>
                      <li>Legs and feet</li>
                      <li>Arms and hands</li>
                    </ul>
                    <p>CTA is commonly used for the following purposes:</p>
                    <p className="nk__content_title">
                      Head and Brain Angiography
                    </p>
                    <p>
                      High quality head and body angiography to examine brain
                      and carotid arteries using a unique magic glass technology
                      through which the system is capable of showing VR(3D) and
                      MIP (Multiplaner reconstruction) image projections,
                      simultaneously on a single image view. Magic glass view is
                      extremely useful to show minute lesions, vascular
                      enhancements.
                    </p>
                    <p className="nk__content_title">Pulmonary Angiography</p>
                    <p>
                      To examine the pulmonary arteries in the lungs to rule out
                      pulmonary embolism, a serious but treatable condition.
                      This is called a CTPA.
                    </p>
                    <p className="nk__content_title">Renal Angiogaphy</p>
                    <p>
                      To visualize blood flow in the renal arteries (those
                      supplying the kidneys) in patients with high blood
                      pressure and those suspected of having kidney disorders.
                      Narrowing (stenosis) of a renal artery is a cause of high
                      blood pressure (hypertension) in some patients and can be
                      corrected. A special computerized method of viewing the
                      images makes renal CT angiography a very accurate
                      examination. Also done in prospective kidney donors.
                    </p>
                    <p className="nk__content_title">
                      Non-invasive heart screening through Cardiac CT -Coronary
                      Angiography
                    </p>
                    <p>
                      Cardiac CT is a non-invasive procedure of using CT scan to
                      determine if you have blocked coronary arteries that may
                      cause a heart attack. This is done through a quick test
                      called coronary artery evaluation.Cardiac CT can also
                      determine other types of heart problems such as congenital
                      anomalies; valve functioning; myocardial infarctions etc.
                    </p>
                    <p>
                      The computed tomography scan (CT scan) is similar to an
                      xray, but produces clearer and more detailed images of the
                      heart. During a CT scan, the x-ray beam moves in a circle
                      around the body. This allows for many different views of
                      the heart and its structure, and provides much greater
                      detail.
                    </p>
                    <p className="nk__content_title">How does it work</p>
                    <p>
                      During the test, X-rays are passed through the body to
                      produce high-resolution, 3-dimensional pictures of the
                      moving heart and great vessels. Analysis of these images
                      reveals if either fatty or calcium deposits (plaques) have
                      built up in the coronary arteries. The test also reveals
                      other structural details of the heart.
                    </p>
                    <p className="nk__content_title">Benefits of Cardiac CT</p>
                    <p>
                      CT Angio (CTA) reveals the number of obstructions and the
                      extent of these obstructions in the blood vessels without
                      any invasion. This helps in planning and preparation for
                      the treatment. For example, if stenting is required, you
                      will have beforehand knowledge of the type and number of
                      stents required.CTA helps in better planning of treatment
                      both in terms of time and money. It can save any last
                      minute surprises when compared to direct admission to a
                      Cath lab. CT Coronary Angiography has emerged as the most
                      reliable non-invasive modality for evaluation of heart
                      diseases.
                    </p>
                    <ul>
                      <li>
                        Takes only a few seconds to evaluate heart condition
                      </li>
                      <li>Non-invasive and painless</li>
                      <li>
                        No hospital admission required, it is an out patient
                        procedure
                      </li>
                      <li>
                        It is a safe and convenient procedure. Cardiac CT
                        imaging process for the patient is almost similar to any
                        other contrast CT scanning
                      </li>
                    </ul>
                    <p className="nk__content_title text-center nk__block">
                      Use of Cardiac CT
                    </p>
                    <p className="nk__content_title">
                      For Preventive Cardiac Screening:
                    </p>
                    <p>
                      CT angiography is highly useful for patients who are
                      asymptomatic but at high risk of having coronary artery
                      disease.
                    </p>
                    <p>
                      Patients presented with atypical chest pain or
                      breathlessness, patients giving history of chronic
                      smoking, chronic hypertension, strong family history of
                      premature heart disease and those with unclear
                      electrocardiographic changes or stress-test results.
                    </p>
                    <p>
                      It is also a screening tool for patients on Cholesterol
                      reducing medications and for patients on exercise and
                      nutrition therapies. The CT coronary angiogram screening
                      will help them to estimate the progress / regress of the
                      disease.
                    </p>
                    <p className="nk__content_title">
                      For Post-Operative Screening and Evaluation:
                    </p>
                    <p>
                      CT angiography is also recommended for patients with
                      history of open heart surgery and had by pass grafting
                      (Coronary Artery Bypass Grafting).It is a convenient way
                      to follow up and evaluate graft functioning and detect any
                      blockages to the by pass grafts inserted into the coronary
                      arteries. Patients who had undergone CABG, CT Angiography
                      can clearly demonstrate grafts blockages if any, the
                      extent of graft functioning, the points of grafts
                      insertions (Ostiums & anastomotic junctions) in three
                      dimensional views.
                    </p>
                    <p className="nk__content_title">
                      For Post-Stenting Screening and Evaluation:
                    </p>
                    <p>
                      With recent advancements in stent technology, insertion of
                      stents into coronary arteries is becoming a preferable
                      methods than the open heart surgeries in many patients,
                      even with triple vessel disease cases. Follow up study to
                      check patency /functioning of stents can also be
                      conveniently performed through CT angiography for many
                      patients.3D images with Stent 3D image with stenosis.
                    </p>
                    <p className="nk__content_title text-center nk__block">
                      CT at Dr. Dodas Diagnostics and Healthcare - what so
                      special ?
                    </p>
                    <p className="nk__content_title">Lowest X-Ray exposure.</p>
                    <p>
                      Our CT is the most advanced CT scanner in the world.
                      Moreover it is also equipped with dose optimization and
                      dose reduction technology for controlling X-Ray radiation
                      to patients. Step and Shoot cardiac CT ensures that the
                      patient is exposed to lowest possible X-rays while the
                      best quality of image is generated.
                    </p>
                    <p className="nk__content_title">Better visualization:</p>
                    <p>
                      Unique adaptive multi cycle reconstruction algorithm
                      achieves temporal resolutions as fast as 53 milliseconds
                      which leads to excellent quality images demonstrating even
                      tiny plaques and calcifications.
                    </p>
                    <p className="nk__content_title">
                      Cardiac Imaging for High BMI patients
                    </p>
                    <p>
                      Doing cardiac screening of heavy patients (high BMI) has
                      always been a challenge. But with the latest technology of
                      CT at Doda Diagnostics and Healthcare, excellent images
                      can be produced for even heavy patients.
                    </p>
                    <p className="nk__content_title">
                      Cardiac Imaging for patients with Arrhythmia
                    </p>
                    <p>
                      If irregular beats occur, they can adversely affect the
                      image quality of the scan .With Philips new Auto
                      arrhythmia editing tool, irregular beats can be
                      automatically detected, edited and the raw data
                      reconstructed again to generate images without any
                      artifacts. Auto arrhythmia editing is used to
                      automatically detect irregular ECG events during a cardiac
                      CT.
                    </p>
                    <p className="nk__content_title">Calcium Scoring</p>
                    <p>
                      Cardiac CT in addition to Coronary Angiography is done for
                      Calcium scoring to measure the extent of calcification in
                      the coronary arteries also. Excess of calcium in the
                      coronary arteries carries a risk for cardiac event. A high
                      calcium score, predisposes to severe stenosis. A calcium
                      score of 0 does not rule out a soft plaque, but definitely
                      rules out significant coronary artery disease. Rupture of
                      a soft plaque is the commonest cause of an acute coronary
                      syndrome.A calcium score of more than 400 is considered
                      severe and it necessitates prevention of advancement of
                      atherosclerosis and plaque formation.
                    </p>
                    <p>
                      Calcium scoring is done as a separate stand-alone test,
                      though is routinely performed as an initial part of the
                      entire cardiac CT examination.
                    </p>
                    <p className="nk__content_title">
                      Indications of CT Coronary Angiography.
                    </p>
                    <ul>
                      <li>
                        Asymptomatic patient with family history of coronary
                        artery disease
                      </li>
                      <li>Patient with high risk factors</li>
                      <li>
                        Prior to non-coronary surgery in the adult population
                        <ul>
                          <li>Pre – ASD repair</li>
                          <li>Pre – Valvular repair</li>
                          <li>Pre – Tumor surgery</li>
                        </ul>
                      </li>
                      <li>Follow up post CABG</li>
                      <li>Atypical chest pain with doubtful coronary origin</li>
                      <li>Evaluation of coronary anomalies</li>
                      <li>
                        Exclusion of coronary stenosis in symptomatic high risk
                        patient
                      </li>
                      <li>Assessment of cardiac neoplasm</li>
                      <li>Assessment of stent patency</li>
                      <li>
                        Detection and characterization of congenital heart
                        disease
                      </li>
                      <li>Diagnosis of pericardial disease</li>
                      <li>Prior to major (non-cardiac) surgery</li>
                      <li>Non-conclusive stress tests</li>
                    </ul>
                    <p className="nk__content_title">
                      Precautions to be taken before a non-invasive angiography:
                    </p>
                    <p>
                      Kidney Function Tests should be done prior to the
                      angiography. Patients Blood Urea Nitrogen and Serum
                      Creatinine reports should be ready before the test.
                    </p>
                    <p>
                      The patient should be fasting for 4 hours prior to the
                      test.
                    </p>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="nk__tab_content">
                    <p>
                      <strong>Ultrasound</strong> involves the use of high
                      frequency sound waves to capture live images from inside
                      of the human body . At Dr Doda`s centre, highly trained
                      ultrasound specialists work on the most highend equipment
                      available in the world today. Our fleet of ultrasound
                      machines include two Voluson E8 Expert, Logiq S7 Expert by
                      GE Healthcare, Philips Epiq 5 and Affinity 50.
                    </p>
                    <p>
                      Ultrasound scanning can help in almost any domain of
                      disease identification. It is useful in pregnancy, it can
                      be done for the breast, lower abdomen, upper abdomen-
                      liver, pancreas, heart, joints, neck etc.
                    </p>

                    <p className="nk__content_title">Fetal Medicine</p>
                    <p>
                      Fetal medicine is a branch of medicine that includes the
                      assessment of fetal growth and wellbeing, the maintenance
                      of fetal health and the diagnosis of fetal illnesses and
                      abnormalities.The concept of Fetal medicine has evolved
                      with the emerging advancements in medical imaging
                      regarding fetus as a separate entity.
                    </p>
                    <p>
                      There is a spectrum of services which are being provided
                      including specialised scans for multiple pregnancies,,
                      3D/4D and Neurosonograms
                    </p>
                    <ul>
                      <li>Early pregnancy</li>
                      <li>First trimester Screening ( NT/NB Scan)</li>
                      <li>
                        Targeted imaging for Fetal anomalies/ Anomaly scan/
                        Level II scan.
                      </li>
                      <li>Cardiac screening</li>
                      <li>Fetal wellbeing scan(28-32 weeks)</li>
                      <li>
                        Fetal growth scan(34 wk onwards) and monitoring of Fetal
                        growth restriction
                      </li>
                      <li>Fetal doppler</li>
                      <li>Neurosonogram</li>
                      <li>Specialised scans for multiple pregnancies.</li>
                    </ul>
                    <p className="nk__content_title">Please note:</p>

                    <p>
                      According to the PC-PNDT Act, a pregnancy ultrasound is
                      done only with a valid prescription having the doctors
                      DMC/MCI registration number, phone number and other
                      details. Patients ID & adress proof along with filling up
                      of Form F is also mandatory.
                    </p>
                    <p className="nk__content_title text-center nk__block">
                      Instructions for patients
                    </p>
                    <p>
                      We recommend prior appointment to avoid waiting period at
                      the centre for Ultrasound.:
                    </p>
                    <p className="nk__content_title">
                      Whether to come empty stomach or not
                    </p>
                    <p>
                      All ultrasound examinations of upper abdomen or whole
                      abdomen requires you to come empty stomach.for
                      visualisation of gall bladder in distended condition.
                      However, practically, we are able to see it well in most
                      of the patients and as a rule, we do not recommend empty
                      stomach. You can have light tea and one or two biscuits in
                      the morning . But once in a while, if gall bladder is
                      completely contracted, we may suggest you to come empty
                      stomach again to have a better look at gall bladder.
                    </p>
                    <p>
                      We do not normally recommend children to be kept empty
                      stomach except in some situations.
                    </p>
                    <p className="nk__content_title">
                      When to come full bladder?
                    </p>
                    <p>
                      Generally all lower abdomen and whole abdomen examinations
                      require full bladder. Also examinations of KUB and early
                      pregnancy (generally up to 3-4 months) will require full
                      bladder. Full bladder means you have to take lot of water
                      at least 1-2 hrs before examination and not pass urine. A
                      well distended urinary bladder helps in seeing uterus,
                      ovaries and adnexa properly in females and prostate in
                      males. It also helps in seeing small stones in bladder and
                      ureters in KUB examination.
                    </p>
                    <p>
                      It is important to note that an urge to pass urine does
                      not indicate you are having full bladder.
                    </p>
                    <p>
                      A really full bladder will make you uncomfortable. We need
                      you to have full bladder, but you should be comfortable.
                      For these examinations, come with some margin to wait as
                      your turn may not come immediately on reaching clinic.
                    </p>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="nk__tab_content">
                    <p>
                      <strong>Color Doppler</strong> is a medical imaging
                      technique utilized for visualization of the blood flow by
                      using color processing in the image. A Color Doppler views
                      how blood moves through arteries and veins. The test
                      combines traditional ultrasound with Doppler
                      ultrasonography. Regular ultrasound uses sound waves that
                      bounce off from blood vessels to create pictures. Doppler
                      views how sound waves are reflected off from moving
                      objects, such as blood.
                    </p>
                    <p className="nk__content_title">
                      The different types of color Doppler ultrasound
                      examinations :
                    </p>
                    <ul>
                      <li>
                        Arterial and venous doppler ultrasound of the
                        extremities - arms or legs.
                      </li>
                      <li>Carotid doppler</li>
                      <li>Renal doppler</li>
                      <li>Obstetric doppler</li>
                      <li>
                        Abdominal doppler for abdominal aorta, liver and portal
                        system
                      </li>
                    </ul>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="nk__tab_content">
                    <p>
                      <strong> Digital X-Ray </strong> : Dr Dodas centre is
                      equiped with a wide range of DR and CR Sytems by AGFA. DR
                      System provides unparalleled image quality and drastically
                      lowers the radiation exposure to the patient.
                    </p>
                    <p>
                      Digital images look sharper and clearer than its analog
                      versions and provide clarity in reporting.
                    </p>
                    <p>
                      The X-Rays pass through the patients body and the digital
                      camera located on the other side of the patient captures
                      the resulting image.
                    </p>
                    <p>
                      All routine plain X-rays and special procedures, including
                      barium studies, intravenous urography, HSG, MCU, etc. are
                      conducted using DR and CR systems from AGFA.
                    </p>

                    <p className="nk__content_title">DIGITAL X-RAYS:</p>
                    <ul>
                      <li>Routine X-Ray</li>
                    </ul>
                    <p className="nk__content_title">Specialised Procedures</p>
                    <ul>
                      <li>I.V.U</li>
                      <li>Sinogram / Sialogram / Fistulogram</li>
                      <li>Barium Swallow</li>
                      <li>Barium meal follow through</li>
                      <li>Barium enema</li>
                      <li>Small bowel enema</li>
                      <li>MCU</li>
                      <li>RGU</li>
                      <li>HSG</li>
                    </ul>
                    <p className="nk__content_title">
                      Instructions for patients for X Ray Examination
                    </p>
                    <p>
                      Generally no preparation is required for routine x-ray
                      examinations. But x-ray of abdomen/ KUB/ LS spine will
                      require you to have preparation previous night with
                      charcol/unienzyme and some laxatives. Special x-ray
                      investigations will required additional preparations and
                      these will be given to you at the time of appointment
                      itself.
                    </p>
                    <p className="nk__content_title">Pregnant women</p>
                    <p>
                      Pregnant women are advised not to get an Xray based
                      investigations like X-ray, OPG Scan, CT Scan, Mammography
                      etc. However in some circumstances, if clinician requires
                      the investigation, kindly inform the radiologist at the
                      centre about your status.
                    </p>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="nk__tab_content">
                    <p>
                      <strong>CONE BEAM COMPUTED TOMOGRAPHY (CBCT)</strong> is
                      designed specifically for dental and maxillofacial
                      imaging. CBCT gives superior image quality (upto 90µm
                      resolution), is faster and patient friendly. CBCT is a
                      completely open system, providing patient freedom from
                      claustrophobia.
                    </p>
                    <p>
                      <strong>Cone Beam CT</strong> uses an amorphous silicon
                      flat panel detector and cone beam technology to get
                      volumetric images with vastly lower radiation dose and
                      higher precision when compared to traditional 16 or 64
                      slice CT scanners. The scanner rotates 360 degrees around
                      a patient`s head in a matter of seconds with the patient
                      in a comfortable standing/sitting position. The single
                      turn motion image capture used in Cone Beam CT is quicker
                      than conventional spiral motion, and is accomplished at a
                      75-90% less X-ray exposure. A target for precise area for
                      exposure, makes the patient exposed to significantly less
                      radiation dose (20-100 times) which makes CBCT safe
                      without any concern for radiation side-effects. Cone beam
                      CT provides views that can be presented as 3D volumes or
                      2D images for diagnosis and advanced planning for
                      patients, dentists, maxillofacial surgeons and health care
                      professionals has revolutionised the practice of dental
                      and maxillofacial surgery. This new age device provides a
                      full range of diagnostic views including complete scans of
                      all oral and maxillofacial structures, paranasal sinuses
                      and airways. The dedicated user-friendly software
                      simplifies treatment planning and provides necessary
                      perspective in many cases. True-size, distortion free,
                      high-resolution images are reconstructed rapidly,
                      tailor-made for the needs of dental and maxillofacial
                      professionals. Acquired information gives the treating
                      doctor more thorough structural knowledge which enables
                      highly accurate diagnosis and treatment plan to create
                      predictable outcomes.
                    </p>
                    <p>
                      CBCT provides all information in one scan including
                      panoramic and cephalometric images and 3D volumes,
                      virtually eliminating the need for conventional
                      orthodontic X-Rays. Patients can be comforted by the
                      knowledge that the doctor will have all the information
                      needed to evaluate their problem and plan treatment.
                    </p>
                    <p className="nk__content_title text-center nk__block">
                      Indications of CBCT
                    </p>
                    <p className="nk__content_title">Dental Imaging</p>
                    <ul>
                      <li>
                        <span>Direct Digital OPG</span>
                      </li>
                      <li>
                        <span>Cephalogram</span>
                      </li>
                      <li>
                        <span>CBCT </span>
                        <ul>
                          <li>
                            <span>
                              Impaction/ Localization of teeth or Foreign body
                            </span>
                          </li>
                          <li>
                            <span>
                              Oral surgery/Third Molar in nerve canal
                              &nbsp;relationship
                            </span>
                          </li>
                          <li>
                            <span>Pre-Implant imaging/Placement/Planning</span>
                          </li>
                          <li>
                            <span>Intra-bony pathology</span>
                          </li>
                          <li>
                            <span>Asymmetry studies</span>
                          </li>
                          <li>
                            <span>Orthodontic surgery</span>
                          </li>
                          <li>
                            <span>Cosmetic surgical planning</span>
                          </li>
                          <li>
                            <span>Endodontic canal assessment</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <p className="nk__content_title">Reporting of CBCT</p>
                    <p>
                      A trained and qualified “Dental Radiologist” interprets
                      the scans and provides the report along with the films.
                      Images and data is also provided on a CD for the referring
                      doctor to view the same on his own computer.
                    </p>
                    <p className="nk__content_title text-center nk__block">
                      Features of CBCT:
                    </p>
                    <p className="nk__content_title">3D-Volume Rendering</p>
                    <p>
                      With volume rendering, clinicians can easily manipulate,
                      enhance, and slice the volume in any orientation or shape
                      for quick and effective diagnosis. Volume rendering is an
                      effective tool for surveying skeletal morphology,
                      dentition, and is a powerful patient demonstration /
                      communication method.
                    </p>

                    <p className="nk__content_title">Section View</p>
                    <p>
                      Clinicians who desire a more in-depth investigation of
                      internal structures can examine slice by slice and sweep
                      through the entire volume using section views. This
                      enables more precise evaluation/measurement of anatomical
                      structures such as assessing the number/shape of root
                      canals, TMJ characteristics, alveolar ridge thickness &
                      anatomy, airway/sinus, bone density/ defects, Hounsfield
                      unit calibration, nerve pathway ID & marking etc.
                    </p>
                    <p className="nk__content_title">Panoramic View</p>
                    <p>
                      Delivers anatomically accurate and precise panoramic
                      images with optimal clarity and detail. Advanced
                      reconstruction of unique anatomic landmarks automatically
                      creates an individualized focal trough specific to each
                      patient. The automatic custom focal arch detection works
                      in conjunction with the patient`s 3-D data to quickly and
                      efficiently extrapolate true and precise panoramic views.
                      There is no image distortion which allows for true
                      measurements.
                    </p>
                    <p className="nk__content_title">Super Ceph</p>
                    <p>
                      This feature gives the ability to easily and quickly
                      construct and view traditional lateral and AP
                      cephalometric radiographs, take measurements, and utilize
                      several image enhancement features which dramatically
                      increase the viewing options allow easier viewing of
                      landmarks.
                    </p>
                    <p className="nk__content_title">
                      Implant Planning and Nerve canal detection
                    </p>
                    <p>
                      Clinicians can perform image based planning for
                      restorative implants or orthodontic miniscrews using the
                      implant planning feature. This feature enables precise
                      implant planning through simultaneous buccal, lingual,
                      vertical, and density visualization.
                    </p>
                    <p className="nk__content_title">
                      Other X Ray Reconstruction
                    </p>
                    <p>
                      The CBCT Scan generates a limitless number of X rays from
                      one scan. Linear and angular measurements can be made on
                      any of the acquired images. Standard examples are coronal
                      and sagittal TMJ slices, bitewings, PA`s, maxillary
                      occlusal and mandibular occlusal views.
                    </p>
                    <p className="nk__content_title">Superimposition Tool</p>
                    <p>
                      Assessment of treatment outcomes with the 3D
                      superimposition of CBCT allows two CBCT scans to be opened
                      at the same time in 3D and to be superimposed based on
                      registering common stable landmarks on each scan.The
                      result is a 3D volume rendering of both scans with
                      different colouring to highlight the difference between
                      the scans.
                    </p>
                    <p className="nk__content_title">
                      Other Dental imaging Facilities
                    </p>
                    <ul>
                      <li>
                        <span>MRI TM Joints</span>
                      </li>
                      <li>
                        <span>Contrast Enhanced MRI Face and Neck</span>
                      </li>
                      <li>
                        <span>
                          Non Contrast Parotid and Submandlbular MR Sialography
                        </span>
                      </li>
                      <li>
                        <span>Contrast Enhanced CT Face and Neck</span>
                      </li>
                      <li>
                        <span>Parotid and Submandibular Sialography</span>
                      </li>
                    </ul>
                    <p className="nk__content_title text-center nk__block">
                      ENT Imaging
                    </p>
                    <p className="nk__content_title">HRCT PNS</p>
                    <ul>
                      <li>
                        <span>PNS Evaluation</span>
                      </li>
                      <li>
                        <span>Chronic sinusitis+/polyps</span>
                      </li>
                      <li>
                        <span>Image guided sinus surgery</span>
                      </li>
                      <li>
                        <span>
                          Paediatric sinus evaluation,treatment and follow up
                        </span>
                      </li>
                    </ul>
                    <p className="nk__content_title">HRCT Temporal Bone</p>
                    <ul>
                      <li>
                        <span>Choclear implant evaluation and follow up</span>
                      </li>
                      <li>
                        <span>Ch otitis mediaCholesteatoma</span>
                      </li>
                      <li>
                        <span>Superior Semicircular canal Dehiscene</span>
                      </li>
                      <li>
                        <span>Otosclerosis</span>
                      </li>
                    </ul>
                    <p className="nk__content_title">TM Joint</p>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="nk__tab_content">
                    <p>
                      <strong>Mammography</strong> is a low dose X-Ray of the
                      breast. It is safe and relatively painless. Breast cancer
                      is the most common cancer in the Indian urban women. ﻿It
                      is well known that breast cancer has a much better
                      survival rate if detected early. The major objective of
                      mammography is early detection of breast cancer.
                    </p>
                    <p className="nk__content_title">Screening Mammography</p>
                    <p>
                      It is important to note that a mammography scan should be
                      done even when no lump or mass can be felt in the breast
                      for early detection.
                    </p>
                    <p>
                      Before the age 40 in women with high risk of breast cancer
                      (personal / family / genetic history).
                    </p>
                    <p>
                      It should be done for every women once they reach 40 yrs
                      of age and repeat at least once every two years, or more
                      frequently if recommended.
                    </p>
                    <p className="nk__content_title">Diagnostic Mammography</p>
                    <p>
                      Done in women with complaints or symptoms pertaining to
                      the breasts such as lump, pain, hardening, nipple
                      discharge, nipple retraction.
                    </p>
                    <p>Done in women with an abnormal screening mammogram.</p>
                    <p>
                      Usually a more detailed examination because additional
                      images need to be taken of the areas of concern and an
                      Ultrasound examination / FNAC / biopsy may need to be
                      performed.
                    </p>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="nk__tab_content">
                    <p>
                      <strong>Bone Densitometery (DEXA)</strong> : A type of
                      x-ray which is used to measure the calcium content of the
                      bone. It is a simple, quick and non-invasive medical test
                      that involves in exposing some parts of the body to very
                      small amount of ionization radiation
                    </p>
                    <p>
                      DEXA bone densitometry is performed to assess the bone
                      mineral density in specific target areas or the whole body
                      and is especially useful in post-menopausal women and
                      older people.
                    </p>
                    <p className="nk__content_title">What is Osteoporosis?</p>
                    <p>
                      Osteoporosis is a metabolic disease affecting the
                      skeleton, which causes a reduction in the amount of bony
                      tissue. Bones are weakened as these tissues are reabsorbed
                      or taken up by local cells. At the core, bones become less
                      dense, on the perimeter, cortical bones lose thickness.
                      Complications from osteoporosis arise as bones become
                      thinner, more porous and susceptible to fractures.
                    </p>
                    <p className="nk__content_title">
                      Type I or Post-Menopausal Osteoporosis
                    </p>
                    <ul>
                      <li>
                        Usually occurs in women menopause. At this time the
                        ovaries produce less estrogen, a female sex hormone. In
                        the absence of estrogen, bone reabsorption decreases,
                        dropping overall bone mass below the maintenance density
                        level, leading to a high risk of fractures.
                      </li>
                    </ul>
                    <p className="nk__content_title">
                      Type II or Age Related Osteoporosis
                    </p>
                    <ul>
                      <li>
                        Age Related Osteoporosis-Inflicts both women and men
                        aged more 70 years. Older people have added risk of low
                        bone mass because bone density peaks at the age of 35
                        and decreases gradually. The ability to absorb calcium
                        from the intestine decreases, thus reducing the calcium
                        inside the body. Also, older people are slightly Vit D
                        deficient, leading to decreased calcium absorption from
                        the intestine. Bone formation responds to physical
                        stress and thus, less activity also decreases bone
                        strength.
                      </li>
                    </ul>
                    <p className="nk__content_title">Effect of Osteoporosis</p>
                    <p>
                      Osteoporosis may go unnoticed if it is asymptomatic. Signs
                      that there has been a reduction in bone mass include:
                    </p>
                    <ul>
                      <li>Lower back pain.</li>
                      <li>
                        Loss of teeth and height over time often accompanied by
                        a stooped posture.
                      </li>
                      <li>
                        Minimal trauma fractures, i.e., fractures occurring
                        without the application of significant force. As bone
                        density decreases the risk of fracture increases.
                      </li>
                    </ul>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RadiologyPage;
