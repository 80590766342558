import React from "react";
import OwlCarousel from "react-owl-carousel3";
import ScrollAnimation from "react-animate-on-scroll";

const options = {
  items: 3,
  loop: true,
  margin: 75,
  nav: true,
  dots: true,
  smartSpeed: 1000,
  autoplay: false,
  autoplayHoverPause: true,
  stagePadding: 0,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsiveClass: false,
  responsive: {
    0: {
      items: 1,
      nav: true,
    },
    480: {
      items: 1,
      nav: true,
    },
    768: {
      items: 2,
      nav: true,
    },
    1000: {
      items: 2,
      nav: true,
      loop: false,
    },
  },
};

const TestimonailSlider = () => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);
  return (
    <React.Fragment>
      <div className="nk_testimonail_slider_container ptb-70">
        <div className="container">
          <div className="section-title">
            <ScrollAnimation animateIn="fadeInUp" offset="100">
              <h2>Client Reviews</h2>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="nk__testimonail_slider">
                {display ? (
                  <OwlCarousel
                    className="testimonail-slider owl-carousel owl-theme"
                    {...options}
                  >
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_top">
                          <img
                            src="/images/Ambassador-Deepak-Vohra.png"
                            alt=""
                            className="img-fluid"
                          />
                          <p>
                            "On two occasions in the last seven days, my wife
                            has come to your Diagnostic Centre for her Chinese
                            virus (Covid 19) and blood tests. I place on record
                            our appreciation for the cool professionalism and
                            dedication with which your staff is handling the
                            unprecedented demand on your world-class facilities.
                            I have always believed that we are capable of rising
                            to any occasion. Crises bring out the best in us.
                            Thank you, Sir! India is proud of her children like
                            you!”
                          </p>
                        </div>
                        <div className="nk_slide_item_bottom">
                          <p>Ambassador Dr. Deepak Vohra</p>
                          <p>
                            Special Advisor to Prime Minister, Lesotho, South
                            Sudan, and Guinea-Bissau
                            <br />
                            Special Advisor to Ladakh Autonomous Hill
                            Development Councils, Leh, and Kargil
                          </p>
                          <p>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_top">
                          <img
                            src="/images/Dr-Karnika-Seth.png"
                            alt=""
                            className="img-fluid"
                          />
                          <p>
                            "Dr. Doda’s Diagnostics & Healthcare provides
                            healthcare services with high degree of
                            professionalism which is simply par excellence! With
                            highly experienced doctors, well qualified
                            technicians and staff and latest technology
                            deployment, the centre delivers dependable
                            healthcare services - it’s truly a seamless
                            experience for me & my family!”
                          </p>
                        </div>
                        <div className="nk_slide_item_bottom">
                          <p>Dr. Karnika Seth</p>
                          <p>
                            Cyberlaw Expert & Advocate, Supreme Court of India
                            <br />
                            Managing Partner - Seth Associates
                          </p>
                          <p>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_top">
                          <img
                            src="/images/dummy-client.svg"
                            alt=""
                            className="img-fluid"
                          />
                          <p>
                            "Dr. Doda’s Diagnostics & Healthcare is reputed to
                            be comprehensively equipped and services delivered
                            by well-trained staff. They are systematic in the
                            operations since the reputation has essentially made
                            it sought after and the crowd of visitors cannot be
                            managed otherwise. Services are mostly prompt and
                            efficient and the staff is found to be concerned
                            about giving a good service to the users. They are
                            cooperative and guide the visitors well. The doctors
                            are also responsive and involved in the smooth and
                            proper diagnosis as many of the tests need elaborate
                            preparations by the patients before the tests.”
                          </p>
                        </div>
                        <div className="nk_slide_item_bottom">
                          <p>Mr. Krishna Narayan</p>
                          <p>Verified Patient Review</p>
                          <p>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_top">
                          <img
                            src="/images/dummy-client.svg"
                            alt=""
                            className="img-fluid"
                          />
                          <p>
                            "Nice experience with this diagnostic centre. Staff
                            was polite and cooperative. Diagnosed by Dr. Doda
                            himself for the first time and he diagnosed in a
                            very patient and skilled manner. Accurate results
                            guaranteed.”
                          </p>
                        </div>
                        <div className="nk_slide_item_bottom">
                          <p>Mr. Saurabh</p>
                          <p>Verified Patient Review</p>
                          <p>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_top">
                          <img
                            src="/images/dummy-client.svg"
                            alt=""
                            className="img-fluid"
                          />
                          <p>
                            "Hi! This is Naveen Arora. I’m very very happy with
                            the accurate results of Dr. Doda's Lab, this centre
                            is serving from last so many years, but every time
                            I’m seeing they are upgrading themselves. Best part
                            of this lab is that their staff is also very good
                            and comforting, very helpful, very cooperative.”
                          </p>
                        </div>
                        <div className="nk_slide_item_bottom">
                          <p>Mr. Naveen Arora</p>
                          <p>Verified Patient Review</p>
                          <p>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_top">
                          <img
                            src="/images/dummy-client.svg"
                            alt=""
                            className="img-fluid"
                          />
                          <p>
                            "MRI brain was advised to me, they gave reports in 2
                            hours which led to timely treatment and a great
                            recovery. The staff is very alert, cooperative and
                            quick...managed things pretty well in this pandemic
                            time...Great experience.”
                          </p>
                        </div>
                        <div className="nk_slide_item_bottom">
                          <p>Mr. Rajesh Arora</p>
                          <p>Verified Patient Review</p>
                          <p>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_top">
                          <img
                            src="/images/dummy-client.svg"
                            alt=""
                            className="img-fluid"
                          />
                          <p>
                            "Very nice experience at Dr. Dodas diagnostics,
                            smooth experience, visit many times, well qualified
                            technology are available there, staff are very
                            polite and helpful. Mr.javed ji helped me. Good
                            service. My best wishes to the all staff members.
                            Thanks a lot”
                          </p>
                        </div>
                        <div className="nk_slide_item_bottom">
                          <p>Shiv Nand</p>
                          <p>Verified Patient Review</p>
                          <p>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                          </p>
                        </div>
                      </div>
                    </div> */}
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_top">
                          <img
                            src="/images/dummy-client.svg"
                            alt=""
                            className="img-fluid"
                          />
                          <p>
                            "Facilities are good and very well organized. I must
                            recommend this for lab test or ultrasound etc. One
                            of the best diagnostic center. Staff are helpful and
                            coperative reports are accurate and comes on time.
                            Brilliant service and help from Dr. Doda's
                            Diagnostics and Healthcare. Very nice and courteous.
                            Super fast service. Keep it up👍 Thanks.”
                          </p>
                        </div>
                        <div className="nk_slide_item_bottom">
                          <p>Mr. Sonu Tiwari</p>
                          <p>Verified Patient Review</p>
                          <p>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_top">
                          <img
                            src="/images/dummy-client.svg"
                            alt=""
                            className="img-fluid"
                          />
                          <p>
                            "Best diagnostic center in Delhi. I had a smooth
                            experience yesterday when I came for my blood tests
                            and CT scan.”
                          </p>
                        </div>
                        <div className="nk_slide_item_bottom">
                          <p>Ms. Jagriti Saini</p>
                          <p>Verified Patient Review</p>
                          <p>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="nk_slide">
                      <div className="nk_slide_item">
                        <div className="nk_slide_item_top">
                          <img
                            src="/images/dummy-client.svg"
                            alt=""
                            className="img-fluid"
                          />
                          <p>
                            "Excellent and fast services by Dr Doda’s imaging. I
                            must particularly thank Mr Yogesh who handles the
                            COVID testing department. He is the most brilliant
                            customer oriented person I’ve ever met. He is very
                            helpful and ensures customers get the COVID report
                            on time. This has been my and my families experience
                            every time. You can ask for help from Yogesh even
                            during midnight and he will always help which is a
                            big relief during these difficult times.”
                          </p>
                        </div>
                        <div className="nk_slide_item_bottom">
                          <p>CA Ashok Khandelwa</p>
                          <p>Verified Patient Review</p>
                          <p>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TestimonailSlider;
