import React from "react";

const VeenaDodaDetailsPage = () => {
  return (
    <div className="doctor-details-area ptb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="doctor-details-item doctor-details-left">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-12">
                  <img src="/images/dr-veena-doda.png" alt="Doctor" />
                </div>
                <div className="col-md-6 col-lg-12">
                  <div className="doctor-details-contact">
                    <h3>Contact info</h3>
                    <ul>
                      <li>
                        <i className="icofont-ui-call"></i>
                        <a href="tel:+919643106759">Call: +91 9643106759</a>
                      </li>
                      <li>
                        <i className="icofont-ui-message"></i>
                        <a href="mailto:dodahealthcare@gmail.com">
                          dodahealthcare@gmail.com
                        </a>
                      </li>
                      <li>
                        <i className="icofont-location-pin"></i>
                        23-B, Pusa Road, Opposite Metro Pillar 115, New Delhi -
                        110005, India.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="doctor-details-work">
                  <h3>Working hours</h3>
                  <div className="appointment-item-two-right">
                    <div className="appointment-item-content">
                      <ul>
                        <li>
                          Monday <span>9:00 AM - 8:00 PM</span>
                        </li>
                        <li>
                          Tuesday <span>9:00 AM - 8:00 PM</span>
                        </li>
                        <li>
                          Wednesday <span>9:00 AM - 8:00 PM</span>
                        </li>
                        <li>
                          Sunday <span>9:00 AM - 8:00 PM</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
            </div>
          </div>

          <div className="col-lg-7">
            <div className="doctor-details-item">
              <div className="doctor-details-right">
                <div className="doctor-details-biography">
                  <h3>DR. VEENA DODA</h3>
                  <p>MEDICAL DIRECTOR</p>
                </div>

                <div className="doctor-details-biography">
                  <p>
                    Dr. Veena Doda graduated from Lady Hardinge Medical College
                    and Hospital, New Delhi, in 1976. She did her residency from
                    Dr. Ram Manohar Lohia Hospital and continued her career in
                    the same hospital. After 34 years of her succesful tenure in
                    providing strategic operations and leadership in various
                    capacities as Head of Department of Transfusion Medicine and
                    Additional Medical Superintendant, Dr Ram Manohar Lohia
                    Hospital, she has taken over as Director, Pathology Lab at
                    Dr Dodas Diagnostics and Healthcare from July 2015 onwards.
                  </p>

                  <p>
                    She has been the recepient of WHO fellowship on HIV and
                    Blood safety from Northwest AIDS Education & Training
                    Centre, University of Washington, Seattle, USA and Jackson
                    Memorial Hospital, University of Miami, USA. She was awarded
                    the Fogarty Fellowship from John Hopkins University,
                    Baltimore, USA on Blood Safety and Intensive HIV Course
                    during which she was also trained at American Red Cross
                    Blood Bank, Chesapeake Region and Holland Laboratory,
                    Rockville, USA.
                  </p>

                  <p>
                    She is also a Post Graduate in Hospital Management from
                    National Institute of Family Welfare and a Gold Medalist
                    from IGNOU university in Hospital and Health management.
                  </p>

                  <p>
                    She has notable contribution in the field of Transfusion
                    Medicine and immense recognition at National and
                    International levels. Dr. Veena Doda has been closely
                    associated with World Health Organisation as a Temporary
                    Adviser and Facilitator on Quality Management. She has been
                    a Technical and Transfusion Expert with Ministry of Health,
                    National AIDS Control Organisation, National/State Blood
                    Transfusion Council, Red Cross, Medical Council of India,
                  </p>

                  <p>
                    She has a number of National and International publications
                    and presentations to her credit. Dr. Veena Doda has
                    delivered prestigious orations at the National Conference of
                    ISBTI and on Foundation Day at King George Medical
                    University, Lucknow. Dr. Doda has written and compiled
                    Technical Training Modules on Blood Safety, and Quality
                    Management Systems Module for NACO, NBTC, Ministry of Health
                    and Family Welfare. She has also contributed a chapter in a
                    Text book on Laboratory and Clinical Transfusion Medicine,
                    Nova Publishers, USA.
                  </p>

                  <p>
                    Besides the honor of Felicitations from many organisations
                    and Lifetime Achievement Award by state blood transfusion
                    council, Dr Doda was conferred with an Award of Excellence
                    for contribution to Transfusion Medicine by National Blood
                    Transfusion Council, National AIDS Control Organisation on
                    World Blood Donor Day by Honorable Minister of Health and
                    Family Welfare on June 14, 2014.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VeenaDodaDetailsPage;
