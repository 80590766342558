import React from "react";
import FacilitiesBox from "../components/FacilitiesBox";

const FacilitiesPage = () => {
  return (
    <React.Fragment>
      <FacilitiesBox />
    </React.Fragment>
  );
};

export default FacilitiesPage;
